import { IonCard, IonCardContent, IonCardHeader } from '@ionic/react';
import { useEquipmentAdd } from '../../../api/useEquipmentAdd';
import { NewEquipmentType } from '../../../models/interfaces/Equipment';
import EquipmentForm from './equipment-submission/EquipmentForm';

interface ProfileContactProps {
  setSection: (section: string) => void;
}

const ProfileContact = ({ setSection }: ProfileContactProps) => {
  // ***** Hooks *****

  const { createEquipment } = useEquipmentAdd();

  // ***** Fonction de gestion d'évènement *****

  const handleNewEquipment = (newEquipment: NewEquipmentType) => {
    console.log('Profile Contact - new Equipment : ', newEquipment);
    try {
      createEquipment.mutate(newEquipment);
      // setSection('account');
    } catch (error) {
      console.log('new equipment error : ', error);
    }
  };

  return (
    <IonCard>
      <IonCardHeader>
        <p>
          Nous essayons constamment d'améliorer l'application pour qu'elle rende
          service au plus grand monde
        </p>
        <p className='ion-padding-vertical'>
          Vous avez trouvé un équipement de station (escalator ou ascenseur) qui
          n'est pas encore référencé ?
        </p>
        <p className='ion-padding-bottom'>
          Remplissez ce formulaire pour nous le soumettre afin que nous
          l'ajoutions
        </p>
      </IonCardHeader>
      <IonCardContent>
        <EquipmentForm onSubmit={handleNewEquipment} />
      </IonCardContent>
    </IonCard>
  );
};

export default ProfileContact;
