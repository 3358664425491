import {
  IonButton,
  IonCard,
  IonCardContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRadio,
  IonRadioGroup,
  IonTextarea,
  useIonAlert,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Equipment } from '../../models/interfaces/Equipment';
import { NewReportType } from '../../models/interfaces/Report';
import { Station } from '../../models/interfaces/Station';
import { User } from '../../models/interfaces/User';
import {
  hasActiveReport,
  sortEquipment,
} from '../../models/verification/station';
import EquipmentCard from '../equipment/EquipmentCard';
import HelpIcon from '../HelpIcon';
import SearchbarStation from '../station/SearchbarStation';

interface LocationStateType {
  station: Station;
  equipment: Equipment;
}

interface ReportFormProps {
  user: User;
  onSubmit: (newReport: NewReportType) => void;
}

const ReportForm = ({ user, onSubmit }: ReportFormProps) => {
  // ***** Hooks *****

  const [errorMsg, setErrorMsg] = useState<string>('');
  const [station, setStation] = useState<Station>();

  const [equipmentToReport, setEquipmentToReport] = useState<string>();

  const durationRef = useRef<HTMLIonInputElement>(null);
  const descriptionRef = useRef<HTMLIonTextareaElement>(null);

  const [presentAlert] = useIonAlert();

  const location = useLocation<LocationStateType>();

  useEffect(() => {
    if (location.state) {
      setStation(location.state.station);
      setEquipmentToReport(location.state.equipment.id);
    }
  }, [location]);

  // ***** Fonction de gestion d'évènements *****

  const handleSelectedStation = (station: Station) => {
    console.log('Report Page - Selected Station : ', station);
    setStation(station);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const duration = durationRef.current?.value;
    const description = descriptionRef.current?.value;

    if (!equipmentToReport) {
      setErrorMsg('Veuillez choisir un équipement parmis ceux disponibles');
      setTimeout(() => {
        setErrorMsg('');
      }, 2000);
    }

    if (
      duration &&
      typeof duration === 'string' &&
      duration.trim().length === 0
    ) {
      setErrorMsg('Format de la durée incorrecte');
      setTimeout(() => {
        setErrorMsg('');
      }, 2000);
    }

    if (
      description &&
      typeof description === 'string' &&
      description.trim().length === 0
    ) {
      setErrorMsg('Format de la description incorrecte');
      setTimeout(() => {
        setErrorMsg('');
      }, 2000);
    }

    const newReport: NewReportType = {
      equipment: { id: equipmentToReport! },
      duration,
      description,
      author: user,
    };

    presentAlert({
      header: "Êtes-vous sûr d'avoir sélectionné le bon équipement ?",
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Confirmer',
          role: 'confirm',
          cssClass: 'alert-button-confirm',
          handler: () => onSubmit(newReport),
        },
      ],
    });
  };

  return (
    <IonCard className='report-section-card'>
      <IonCardContent className='report-card-content'>
        <form onSubmit={handleSubmit}>
          <p className='ion-text-center'>Vous avez constaté un incident ?</p>
          <p className='ion-padding-bottom ion-text-center'>
            Merci de le signaler à la communauté
          </p>
          <h3 className='ion-padding-start ion-padding-vertical report-subtitle'>
            Station concernée
          </h3>
          <SearchbarStation onStationSelect={handleSelectedStation} />
          {station && station.equipments.length > 0 ? (
            <>
              <div className='ion-text-center ion-padding'>
                <IonButton disabled>
                  {station?.name ?? location.state.station.name}
                </IonButton>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3 className='ion-padding-start report-subtitle'>
                  Equipement concerné
                </h3>
                <HelpIcon cssClass='help-icon' />
              </div>
              <IonList>
                <IonRadioGroup
                  value={equipmentToReport}
                  onIonChange={(e) => setEquipmentToReport(e.target.value)}
                >
                  {sortEquipment(station.equipments).map(
                    (equipment) =>
                      !hasActiveReport(equipment) && (
                        <IonItem key={equipment.id}>
                          <IonCard>
                            <EquipmentCard
                              page='report'
                              station={station}
                              equipment={equipment}
                            />
                          </IonCard>
                          <IonRadio
                            value={equipment.id}
                            slot='end'
                            className='report-radio'
                          />
                        </IonItem>
                      )
                  )}
                </IonRadioGroup>
              </IonList>
            </>
          ) : (
            <p>Cette station n'a pas d'équipements</p>
          )}
          <IonItem className='ion-padding-horizontal'>
            <IonLabel position='floating'>Durée</IonLabel>
            <IonInput placeholder="Durée d'indisponibilité" ref={durationRef} />
            <IonNote slot='helper' className='report-note'>
              Si précisée sur un panneau
            </IonNote>
          </IonItem>
          <IonItem className='ion-padding-horizontal ion-margin-bottom'>
            <IonLabel position='floating'>Description</IonLabel>
            <IonTextarea
              className='report-textarea'
              placeholder='Description'
              ref={descriptionRef}
              rows={1}
              autoGrow
            />
            <IonNote slot='helper' className='report-note'>
              Pour mieux décrire l'incident
            </IonNote>
          </IonItem>

          <IonButton
            data-testid='submit-report'
            className='ion-padding-horizontal'
            type='submit'
          >
            Valider
          </IonButton>
          {errorMsg && (
            <p className='ion-padding register-error' color='danger'>
              {errorMsg}
            </p>
          )}
        </form>
      </IonCardContent>
    </IonCard>
  );
};

export default ReportForm;
