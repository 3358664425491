import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { Line } from '../../../../models/interfaces/Line';
import { LineStation } from '../../../../models/interfaces/LineStation';

interface SelectLineProps {
  lineStations: LineStation[];
  onLineSelect: (line: Line) => void;
}

const SelectLine = ({ lineStations, onLineSelect }: SelectLineProps) => {
  return (
    <IonItem>
      <IonLabel position='floating'>Ligne</IonLabel>
      <IonSelect
        interface='popover'
        placeholder='Sélectionner la ligne'
        onIonChange={(e) => onLineSelect(e.target.value)}
      >
        {lineStations.map((lineStations) => (
          <IonSelectOption key={lineStations.line.id} value={lineStations.line}>
            {lineStations.line.name}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};

export default SelectLine;
