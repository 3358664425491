import {
  IonAccordion,
  IonAccordionGroup,
  IonIcon,
  IonItem,
  IonLabel,
  IonTextarea,
  useIonToast,
} from '@ionic/react';
import { sendSharp } from 'ionicons/icons';
import React, { useState } from 'react';
import { Report } from '../../models/interfaces/Report';
import { User } from '../../models/interfaces/User';
import { NewUserCommentReport } from '../../models/interfaces/UserCommentReport';
import ReportCommentItem from './ReportCommentItem';

interface ReportCommentProps {
  report: Report;
  user: User;
  onCommentClick: (comment: NewUserCommentReport) => void;
}

const ReportComment = ({
  report,
  user,
  onCommentClick,
}: ReportCommentProps) => {
  // ***** Hooks *****

  const [commentMsg, setCommentMsg] = useState<string>('');

  const [present] = useIonToast();

  // ***** Fonction de vérification *****

  const hasComments = (report: Report) => {
    return report.comments.some((userComment) => userComment.comment);
  };

  // ***** Fonction de gestions d'évènements *****

  const handleNewComment = (reportToComment: Report) => {
    if (commentMsg.trim().length === 0) {
      present({
        message: `Format incorrect`,
        duration: 1000,
        position: 'bottom',
        color: 'danger',
        cssClass: 'ion-text-center',
      });
      return;
    }

    if (user && commentMsg) {
      const newReaction: NewUserCommentReport = {
        comment: commentMsg,
        report: reportToComment,
        user,
      };

      onCommentClick(newReaction);

      setCommentMsg('');
    }
  };

  return (
    <IonAccordionGroup>
      <IonAccordion value='first'>
        <IonItem slot='header'>
          <IonLabel>Commentaires</IonLabel>
        </IonItem>
        <div className='ion-padding report-comments' slot='content'>
          {hasComments(report) ? (
            report.comments.map(
              (userComment, i) =>
                userComment.comment && (
                  <div
                    key={`${userComment.comment}-${i}`}
                    className='report-comment-container'
                  >
                    <ReportCommentItem userComment={userComment} user={user} />
                  </div>
                )
            )
          ) : (
            <>Pas de commentaires pour le moment</>
          )}
        </div>
        <IonItem slot='content'>
          <IonTextarea
            placeholder='Entrer votre commentaire'
            autoGrow={true}
            value={commentMsg}
            rows={1}
            debounce={500}
            onIonChange={(e) => setCommentMsg(e.target.value!)}
          />
          <IonIcon
            icon={sendSharp}
            slot='end'
            className='ion-align-self-center icon-send'
            onClick={() => handleNewComment(report)}
          />
        </IonItem>
      </IonAccordion>
    </IonAccordionGroup>
  );
};

export default ReportComment;
