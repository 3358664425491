import { IonButton, useIonAlert } from '@ionic/react';
import { useReports } from '../../api/useReports';
import { Report } from '../../models/interfaces/Report';

interface ReportCloseProps {
  report: Report;
}

const ReportClose = ({ report }: ReportCloseProps) => {
  // ***** Hooks *****

  const [presentAlert] = useIonAlert();

  const { closeReport } = useReports();

  // ***** Fonction de gestion d'évènements *****

  const handleCloseReport = (reportToClose: Report) => {
    presentAlert({
      header: 'Clore cet incident ?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Confirmer',
          role: 'confirm',
          cssClass: 'alert-button-confirm',
          handler: () => closeReport.mutate(reportToClose.id),
        },
      ],
    });
  };

  return (
    <div className='ion-text-center ion-padding-top'>
      <IonButton color='warning' onClick={() => handleCloseReport(report)}>
        Clore l'incident
      </IonButton>
    </div>
  );
};

export default ReportClose;
