import React, { useState } from 'react';
import DataList from './DataList';
import DataSelect, { DataTypes } from '../DataSelect';

interface ListSectionProps {
  section: string;
}

const ListSection = ({ section }: ListSectionProps) => {
  const [data, setData] = useState<DataTypes>();

  return (
    <>
      <DataSelect
        section={section}
        onSelect={(dataType: DataTypes) => setData(dataType)}
      />

      {data && <DataList dataType={data} />}
    </>
  );
};

export default ListSection;
