import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { AxiosResponse } from 'axios';
import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { axiosPublic } from '../api/axios';
import { errorInfo } from '../models/form-validate/errorMessage';
import { isValid } from '../models/form-validate/validation';

let id = '';
let token = '';

const PasswordReset = () => {
  const [errorMsg, setErrorMsg] = useState<string>('');

  const passwordRef = useRef<HTMLIonInputElement>(null);
  const passwordConfirmRef = useRef<HTMLIonInputElement>(null);

  const [present] = useIonToast();
  const location = useLocation();
  const history = useHistory();

  // Cette classe permet d'obtenir un tableau de toutes les paires key/value des search Params (la clé est ce qu'il y a à gauche du "=" et la valeur ce qu'il y a à droite) séparés par un "&"
  const searchParams = new URLSearchParams(location.search);

  // Pour chaque paire, on met à jour nos variables
  searchParams.forEach((param, i) => {
    console.log('key', i);
    if (i === 'id') {
      id = param;
    }
    if (i === 'token') {
      token = param;
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setErrorMsg('');

    if (passwordRef.current && passwordConfirmRef.current) {
      const password = passwordRef.current.value;
      const passwordConfirm = passwordConfirmRef.current.value;

      if (typeof password === 'string' && !isValid('password', password)) {
        setErrorMsg(errorInfo('password'));
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
        return;
      }

      if (password !== passwordConfirm) {
        setErrorMsg('Combinaison de mots de passe incorrecte');
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
        return;
      }

      try {
        const response: AxiosResponse = await axiosPublic.post(
          `auth/password/reset/${id}/${token}`,
          { password }
        );
        console.log('Reset Password - Response : ', response.data);
        present({
          message:
            'Votre mot de passe a bien été réinitialisé, vous pouvez vous reconnecter',
          color: 'success',
          duration: 3000,
          position: 'top',
          cssClass: 'ion-text-center',
        });
        history.push('/login');
      } catch (error: any) {
        console.log('Reset Password - Error : ', error);
        setErrorMsg(error.response.data.message);
      }
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons
            className='ion-padding-start'
            slot='start'
            style={{ width: '80px', paddingBlock: '16px' }}
          >
            <IonRouterLink routerLink='/'>
              <IonImg src='/assets/logo.png' />
            </IonRouterLink>
          </IonButtons>
          <IonTitle slot='end'>Mot de passe oublié ?</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='form-container ion-padding'>
          <form onSubmit={handleSubmit}>
            <p style={{ marginInline: '10px' }} className='ion-margin-bottom'>
              Réinitialisation de votre mot de passe
            </p>
            <IonCard className='login-card'>
              <IonCardContent>
                <IonItem>
                  <IonLabel position='floating'>
                    Mot de passe <span style={{ color: 'red' }}>*</span>
                  </IonLabel>
                  <IonInput
                    placeholder='Entrez votre mot de passe'
                    type='password'
                    ref={passwordRef}
                    required
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position='floating'>
                    Confirmation mot de passe{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </IonLabel>
                  <IonInput
                    placeholder='Confirmer votre mot de passe'
                    type='password'
                    ref={passwordConfirmRef}
                    required
                  />
                </IonItem>
                <IonButton className='ion-margin-top' type='submit'>
                  Valider
                </IonButton>
                {errorMsg && (
                  <p className='ion-padding register-error' color='danger'>
                    {errorMsg}
                  </p>
                )}
              </IonCardContent>
            </IonCard>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PasswordReset;
