import { IonContent, IonIcon, useIonPopover } from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import React from 'react';

interface HelpIconProps {
  message?: string;
  cssClass: string;
}

let defaultMessage =
  'Il manque un équipement à cette station ? Contactez-nous via votre profil pour nous le signaler';

const HelpIcon = ({ cssClass, message = defaultMessage }: HelpIconProps) => {
  const Popover = () => (
    <IonContent className='ion-padding popover-content'>{message}</IonContent>
  );

  const [presentPopover] = useIonPopover(Popover);
  return (
    <>
      <IonIcon
        icon={helpCircleOutline}
        size='large'
        className={cssClass}
        onClick={(e: any) => presentPopover({ event: e, alignment: 'start' })}
      />
    </>
  );
};

export default HelpIcon;
