import {
  CreateAnimation,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonFooter,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  useIonModal,
  useIonToast,
} from '@ionic/react';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import {
  chevronDownOutline,
  chevronUpOutline,
  ellipse,
  star,
  starOutline,
} from 'ionicons/icons';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { useStationById } from '../../api/useStationById';
import { useAuth } from '../../context/AuthContext';
import {
  getEquipmentTypePicture,
  linePictureSrc,
} from '../../models/formats/picture';
import { Equipment } from '../../models/interfaces/Equipment';
import { Station } from '../../models/interfaces/Station';
import { User } from '../../models/interfaces/User';
import { sortEquipment } from '../../models/verification/station';
import HelpIcon from '../HelpIcon';
import StationModal, { ReportIncidentType } from './StationModal';
import './StationSmall.css';

interface StationSmallProps {
  id: string;
  setLocation: (location: string) => void;
}

const StationSmall = ({ id, setLocation }: StationSmallProps) => {
  // ***** Hooks *****
  const [showMore, setShowMore] = useState<boolean>(false);

  const dropwdownAnimationRef = useRef<CreateAnimation>(null);

  const axiosPrivate = useAxiosPrivate();
  const history = useHistory();
  const [present] = useIonToast();

  const { currentUser, setCurrentUser } = useAuth();

  // ***** Tanstack *****

  const queryClient = useQueryClient();

  const { getStationById } = useStationById(id);

  const { data: station, isLoading, isError } = getStationById;

  const updateFavStationsQuery = useMutation({
    mutationFn: async (updatedStations: Station[]) => {
      const response: AxiosResponse<User> = await axiosPrivate.patch(
        `/user/${currentUser?.email}`,
        { stations: updatedStations }
      );
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['station']);
    },
  });

  const [presentModal, dismiss] = useIonModal(StationModal, {
    onDismiss: (data: string | ReportIncidentType, role: string) => {
      dismiss(data, role);
      setLocation('/report');
    },
    station: station,
  });

  if (isLoading) return <h1>Chargement de la station...</h1>;
  if (isError) return <h1>Erreur de chargement de la station...</h1>;

  // ***** Divers *****

  const calculateMaxHeight = (equipmentsNumber: number) => {
    const result = 64 + equipmentsNumber * 48 + 52 + 50;
    return `${result}px`;
  };

  // ***** Fonctions de vérifications *****

  const hasActiveReport = (equipment: Equipment) => {
    return equipment.reports?.some((report) => !report.isOver);
  };

  const isStationInFavorites = (station: Station) => {
    return currentUser?.stations?.some(
      (favStation) => favStation.id === station.id
    );
  };

  const stationActiveEquipments = station.equipments.filter(
    ({ isVerified }) => isVerified
  );

  // ***** Fonctions de gestion d'évènements *****

  const handleFavorites = (clickedStation: Station) => {
    let updatedFavStations: Station[] = [...currentUser!.stations];
    console.log(
      'StationSmall - FavStationUpdate - old fav stations : ',
      updatedFavStations
    );
    if (isStationInFavorites(clickedStation)) {
      updatedFavStations = updatedFavStations.filter(
        (favStation) => favStation.id !== clickedStation.id
      );
    } else {
      updatedFavStations = [...updatedFavStations, clickedStation];
    }
    console.log(
      'StationSmall - FavStationUpdate - new fav stations : ',
      updatedFavStations
    );

    try {
      updateFavStationsQuery.mutate(updatedFavStations);
      currentUser &&
        setCurrentUser({ ...currentUser, stations: updatedFavStations });
      present({
        message: `Station ${
          isStationInFavorites(clickedStation) ? 'retirée des' : 'ajoutée aux'
        }  favoris`,
        duration: 1000,
        position: 'top',
        color: 'success',
        cssClass: 'ion-text-center',
      });
    } catch (error) {
      console.log('StationSmall - FavStationUpdate - Error : ', error);
    }
  };

  const handleShowMoreClick = () => {
    if (dropwdownAnimationRef.current) {
      setShowMore(!showMore);
      dropwdownAnimationRef.current.animation.play();
    }
  };

  // ***** Fonction gérant l'ouverture et fermeture de la modal *****

  const openModal = () => {
    presentModal({
      onWillDismiss: (e: CustomEvent<OverlayEventDetail>) => {
        if (e.detail.role === 'report') {
          history.push({
            pathname: '/report',
            state: e.detail.data,
          });
        }
      },
    });
  };

  return (
    <div className='ion-margin-bottom'>
      {station && (
        <CreateAnimation
          ref={dropwdownAnimationRef}
          duration={150}
          keyframes={[
            {
              offset: 0,
              maxHeight: !showMore
                ? calculateMaxHeight(stationActiveEquipments.length)
                : '220px',
            },
            {
              offset: 1,
              maxHeight: !showMore
                ? '220px'
                : calculateMaxHeight(stationActiveEquipments.length),
            },
          ]}
        >
          <IonCard
            className={
              stationActiveEquipments.length >= 3
                ? 'station-card card-shrinked'
                : 'station-card'
            }
          >
            <IonCardHeader className='station-card-header'>
              {/* Affichage des images des lignes */}
              <div style={{ display: 'flex', gap: '5px' }}>
                {station.lines_station
                  .map((lineStation) => (
                    <IonImg
                      key={lineStation.id}
                      src={linePictureSrc(lineStation.line.picture)}
                      alt={lineStation.line.name}
                      style={{ width: '20px', height: '20px' }}
                    />
                  ))
                  .sort()}
              </div>
              <IonCardTitle className='station-card-title ion-padding-horizontal'>
                {station.name}
              </IonCardTitle>
              <IonIcon
                icon={isStationInFavorites(station) ? star : starOutline}
                style={{ width: '25px', minWidth: '25px' }}
                color='warning'
                size='large'
                className='station-favorite-icon'
                onClick={() => handleFavorites(station!)}
              />
            </IonCardHeader>
            <IonCardContent
              className={
                stationActiveEquipments.length > 3
                  ? 'station-card-content many-equipments'
                  : 'station-card-content'
              }
            >
              {station &&
                (stationActiveEquipments.length > 0 ? (
                  <>
                    {sortEquipment(stationActiveEquipments).map((equipment) => (
                      <IonItem key={equipment.id} className='station-card-item'>
                        <IonImg
                          src={getEquipmentTypePicture(equipment)}
                          style={{
                            width: '30px',
                            height: '30px',
                            marginRight: '20px',
                          }}
                        />
                        <IonLabel>{equipment.type.name}</IonLabel>
                        <IonIcon
                          icon={ellipse}
                          size='small'
                          color={
                            hasActiveReport(equipment) ? 'danger' : 'success'
                          }
                        />
                      </IonItem>
                    ))}
                    <div className='ion-padding-top station-card-footer'>
                      <IonButton
                        className='station-card-button'
                        onClick={() => openModal()}
                      >
                        Détails
                      </IonButton>
                      <HelpIcon cssClass='help-icon ion-text-end' />
                    </div>
                  </>
                ) : (
                  <div className='no-equipment-container ion-padding-top'>
                    <p>Cette station n'a pas d'équipements</p>
                    <HelpIcon cssClass='help-icon ion-text-end' />
                  </div>
                ))}
            </IonCardContent>
            {stationActiveEquipments.length >= 3 && (
              <IonFooter
                className='station-card-dropdown ion-text-center'
                onClick={() => handleShowMoreClick()}
              >
                <IonIcon
                  icon={showMore ? chevronUpOutline : chevronDownOutline}
                  size='large'
                  className='help-icon'
                />
              </IonFooter>
            )}
          </IonCard>
        </CreateAnimation>
      )}
    </div>
  );
};

export default StationSmall;
