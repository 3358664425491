import React, { useState } from 'react';
import DataForm from './DataForm';
import DataSelect, { DataTypes } from '../DataSelect';

interface AddSectionProps {
  section: string;
}

const AddSection = ({ section }: AddSectionProps) => {
  const [data, setData] = useState<DataTypes>();

  return (
    <>
      <DataSelect
        section={section}
        onSelect={(dataType: DataTypes) => setData(dataType)}
      />

      {data && <DataForm dataType={data} />}
    </>
  );
};

export default AddSection;
