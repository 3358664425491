import { IonCardContent, IonTitle } from '@ionic/react';
import React, { useState } from 'react';
import { Station } from '../../models/interfaces/Station';
import SearchbarStation from '../station/SearchbarStation';
import StationSmall from '../station/StationSmall';

interface SearchStationSectionProps {
  setLocation: (location: string) => void;
}

const SearchStationSection = ({ setLocation }: SearchStationSectionProps) => {
  // ***** Hooks *****
  const [station, setStation] = useState<Station>();

  const handleSelectedStation = (selectedStation: Station) => {
    setStation(selectedStation);
  };
  return (
    <IonCardContent className='fav-station-container'>
      <IonTitle className='ion-text-center'>Rechercher une station</IonTitle>
      <p className='ion-text-center ion-padding-vertical'>
        Vous préparez un trajet en métro ?
      </p>
      <p className='ion-text-center ion-padding-bottom'>
        Vérifier si les stations empruntées possèdent des équipements et s'ils
        fonctionnent
      </p>
      <p className='ion-text-center ion-padding-bottom'>
        Ajouter la station en favori si vous l'empruntez régulièrement
      </p>
      <p className='ion-text-center ion-padding-bottom'>
        L'équipement était marqué comme fonctionnant et il ne l'est pas lors de
        votre passage ? Signalez le via la page dédiée en bas de votre écran
      </p>
      <div className='ion-padding-horizontal'>
        <SearchbarStation onStationSelect={handleSelectedStation} />
      </div>
      {station && <StationSmall id={station.id} setLocation={setLocation} />}
    </IonCardContent>
  );
};

export default SearchStationSection;
