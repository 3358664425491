import { IonIcon, useIonAlert } from '@ionic/react';
import { createOutline } from 'ionicons/icons';
import { useReports } from '../../api/useReports';
import { getDate } from '../../models/formats/date';
import { Report } from '../../models/interfaces/Report';
import { User } from '../../models/interfaces/User';
import ReportClose from './ReportClose';
import ReportReactions from './ReportReactions';

interface ReportDetailsProps {
  report: Report;
  user: User;
}

const ReportDetails = ({ report, user }: ReportDetailsProps) => {
  // ***** Hooks *****

  // const axiosPrivate = useAxiosPrivate();
  const { editReport } = useReports();

  const [presentAlert] = useIonAlert();

  // ***** Fonction de gestion d'évènements *****

  const handleEditReport = (reportToUpdate: Report, type: string) => {
    presentAlert({
      header: 'Edition Incident',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Confirmer',
          role: 'confirm',
          cssClass: 'alert-button-confirm',
          handler: (data) => {
            if (type === 'Durée') {
              return editReport.mutate({
                reportId: reportToUpdate.id,
                data: {
                  duration: data[0],
                },
              });
            } else if (type === 'Description') {
              return editReport.mutate({
                reportId: reportToUpdate.id,
                data: {
                  description: data[0],
                },
              });
            }
          },
        },
      ],
      inputs: [
        {
          placeholder: type,
        },
      ],
    });
  };

  return (
    <div key={report.id}>
      <h3 className='report-title'>Incident</h3>
      <div className='ion-margin-top'>
        <p className='equipment-prop-title'>Signalé le</p>
        <p>{getDate(report.created_at)}</p>
      </div>
      {report.created_at !== report.updated_at && (
        <div className='ion-margin-top'>
          <p className='equipment-prop-title'>Modifié le</p>
          <p>{getDate(report.updated_at)}</p>
        </div>
      )}
      <div className='ion-margin-top'>
        <p className='equipment-prop-title'>Durée</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p>{report.duration ? report.duration : 'non communiquée'}</p>
          {(report.authorId === user.id || user.role === 'admin') && (
            <IonIcon
              icon={createOutline}
              color='success'
              size='small'
              className='equipment-icon ion-padding-start'
              style={{ minWidth: '6%' }}
              onClick={() => handleEditReport(report, 'Durée')}
            />
          )}
        </div>
      </div>
      <div className='ion-margin-top ion-margin-bottom'>
        <p className='equipment-prop-title'>Description</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p>{report.description ? report.description : 'non communiquée'}</p>
          {(report.authorId === user.id || user.role === 'admin') && (
            <IonIcon
              icon={createOutline}
              color='success'
              size='small'
              className='equipment-icon ion-padding-start'
              style={{ minWidth: '6%' }}
              onClick={() => handleEditReport(report, 'Description')}
            />
          )}
        </div>
      </div>
      <ReportReactions report={report} />
      {(report.authorId === user.id || user.role === 'admin') && (
        <ReportClose report={report} />
      )}
    </div>
  );
};

export default ReportDetails;
