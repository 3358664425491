import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { Station } from '../../models/interfaces/Station';
import SearchbarStation from '../station/SearchbarStation';

interface StationFilterProps {
  onStationSelect: (stationName: string) => void;
}

const StationFilter = ({ onStationSelect }: StationFilterProps) => {
  // ***** Hooks *****

  const [selectedStation, setSelectedStation] = useState<string>('');

  // ***** Fonctions gestion d'évènement *****

  const handleSelectedStation = (station: Station) => {
    setSelectedStation(station.name);
    onStationSelect(station.name);
  };

  const handleCancelStation = () => {
    setSelectedStation('');
    onStationSelect('');
  };

  return (
    <div style={{ paddingInlineStart: '16px', paddingInlineEnd: '8px' }}>
      <h3>Par station</h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <SearchbarStation onStationSelect={handleSelectedStation} />
        {selectedStation && (
          <IonIcon
            icon={closeOutline}
            size='large'
            color='danger'
            class='equipment-icon'
            onClick={handleCancelStation}
          />
        )}
      </div>
    </div>
  );
};

export default StationFilter;
