import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { isPlatform } from '@ionic/core';
import { useEffect, useState } from 'react';

export interface UserPhoto {
  filepath: string;
  webviewPath?: string;
}

// Key for the 'localstorage' / device storage
const PHOTO_STORAGE = 'photos';

export const usePhotoGallery = () => {
  const [photos, setPhotos] = useState<UserPhoto[]>([]);

  // method to save a picture
  const savePicture = async (
    photo: Photo, // newly captured device photo
    fileName: string // will provide a path for the file to be stored to
  ): Promise<UserPhoto> => {
    let base64Data: string;
    // "hybrid" will detect Cordova or Capacitor, meaning its running on mobile
    if (isPlatform('hybrid')) {
      // directly extract the base64 data with Filesystem
      const file = await Filesystem.readFile({
        path: photo.path!,
      });
      base64Data = file.data;
    } else {
      // converts the photo to base64 format if we are on web
      base64Data = await base64FromPath(photo.webPath!);
    }
    // we use the Filesystem API from capacitor to work with files on the device by feeding the data to the writeFile function
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      // The directory where the files will be stored
      directory: Directory.Data,
    });

    // Hybrid means app is running on Capacitor or Cordova, the 2 native runtimes of Ionic
    if (isPlatform('hybrid')) {
      // Display the new image by rewriting the 'file://' path to HTTP if app running on mobile because Capacitor is hosted on a local HTTP server and can't work with file://
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      return {
        filepath: savedFile.uri,
        webviewPath: Capacitor.convertFileSrc(savedFile.uri),
      };
    } else {
      // Use webPath to display the new image instead of base64 since it's
      // already loaded into memory
      return {
        filepath: fileName,
        webviewPath: photo.webPath,
      };
    }
  };

  // Retrieve data when hook loads
  useEffect(() => {
    const loadSaved = async () => {
      // Get the value of 'token' stored in device/local storage
      const { value } = await Preferences.get({ key: PHOTO_STORAGE });
      // If there is a value, we convert the json object into an object
      const photosInPreferences = (
        value ? JSON.parse(value) : []
      ) as UserPhoto[];

      // If running on the web
      if (!isPlatform('hybrid') && photosInPreferences.length > 0) {
        // On web, we need to read each image into base64 format because Filesystem API stores them like that
        for (let photo of photosInPreferences) {
          const file = await Filesystem.readFile({
            path: photo.filepath,
            directory: Directory.Data,
          });
          // Web platform only: Load the photo as base64 data
          photo.webviewPath = `data:image/jpeg;base64,${file.data}`;
        }
      }
      setPhotos(photosInPreferences);
    };
    loadSaved();
  }, []);

  const takePhoto = async () => {
    // we get the photo from Capacitor's Camera API
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    });

    const fileName = new Date().getTime() + '.jpeg';
    // We pass the photo and filename in the savePicture method
    const savedFileImage = await savePicture(photo, fileName);
    const newPhotos = [savedFileImage, ...photos];
    setPhotos(newPhotos);

    console.log('Photo Hook - savedFileImage : ', savedFileImage);
    // Save the photos array in device storage or localstorage (for web) each time a photo is taken
    Preferences.set({ key: PHOTO_STORAGE, value: JSON.stringify(newPhotos) });
    return newPhotos;
  };

  const deletePhotos = () => {
    photos.forEach(async (photo) => {
      await Filesystem.deleteFile({
        path: photo.filepath,
        directory: Directory.Data,
      });
    });
    Preferences.remove({ key: PHOTO_STORAGE });
    setPhotos([]);
  };

  const startUploadImage = (photos: UserPhoto[]) => {
    console.log('start upload - photos : ', photos);
    const formData = new FormData();
    photos.forEach(async (photo) => {
      console.log('début boucle - photo : ', photo);
      const response = await fetch(photo.webviewPath!);
      console.log('upload - response : ', response);
      const blob = await response.blob();
      console.log('upload - blob : ', blob);
      formData.append('photo', blob, photo.filepath);
      console.log('formData : ', formData.entries());
    });
    return formData;
  };

  return {
    photos,
    takePhoto,
    deletePhotos,
    startUploadImage,
  };
};

// This function downloads a file from 'path' and returns a base64 representation of that file
export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    // Allow to read files content (File or Blob) asynchronously (thats why we use a Promise)
    const reader = new FileReader();
    // If there is an error during the read of the file
    reader.onerror = reject;
    // If the reading is successfully completed, allows to access to the 'result' prop of reader
    reader.onload = () => {
      // If file content is a string, the promise is resolved and the function goes further
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject('method did not return a string');
      }
    };
    // Start the reading of the blob content. When finished, 'result' contains an data URL representing the file's data
    reader.readAsDataURL(blob);
  });
}
