import { IonIcon, IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { closeOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { Line } from '../../models/interfaces/Line';

interface LineFilterProps {
  onLineSelect: (lineName: string) => void;
}

const LineFilter = ({ onLineSelect }: LineFilterProps) => {
  // ***** Hooks *****

  const [selectedLine, setSelectedLine] = useState<string>('');

  const axiosPrivate = useAxiosPrivate();

  // ***** Tanstack *****

  const {
    data: lines,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['lines'],
    queryFn: async () => {
      const response: AxiosResponse<Line[]> = await axiosPrivate.get('/lines');
      const metroLines = response.data.filter(
        ({ name }) =>
          !name.includes('RER') &&
          name !== 'METRO 15' &&
          name !== 'METRO 16' &&
          name !== 'METRO 17' &&
          name !== 'METRO 18'
      );
      return metroLines;
    },
  });

  if (isLoading) return <h2>Loading...</h2>;
  if (isError) return <h2>Erreur du chargement des lignes</h2>;

  // ***** Fonctions gestion d'évènement *****

  const handleSelectedLine = (lineName: string) => {
    setSelectedLine(lineName);
    onLineSelect(lineName);
  };

  const handleCancelLine = () => {
    setSelectedLine('');
  };

  return (
    <div className='ion-padding'>
      <h3>Par ligne</h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <IonItem style={{ width: '100%' }}>
          <IonSelect
            style={{ width: '100%' }}
            interface='popover'
            value={selectedLine}
            onIonChange={(e) => handleSelectedLine(e.target.value)}
          >
            <IonSelectOption value=''>Sélectionner une ligne</IonSelectOption>
            {lines &&
              lines.map(({ id, name }) => (
                <IonSelectOption key={id} value={name}>
                  {name}
                </IonSelectOption>
              ))}
          </IonSelect>
        </IonItem>
        {selectedLine && (
          <IonIcon
            icon={closeOutline}
            size='large'
            color='danger'
            class='equipment-icon'
            onClick={handleCancelLine}
          />
        )}
      </div>
    </div>
  );
};

export default LineFilter;
