import { IonCard, IonCardHeader, IonCol, IonImg, IonRow } from '@ionic/react';
import { useStationById } from '../../../api/useStationById';
import { Equipment } from '../../../models/interfaces/Equipment';
import EquipmentCard from '../../equipment/EquipmentCard';
import EquipmentValidationButton from './EquipmentValidationButton';

interface EquipmentContentProps {
  equipment: Equipment;
}

const EquipmentContent = ({ equipment }: EquipmentContentProps) => {
  // ***** Hooks *****

  const { getStationById } = useStationById(equipment.stationId);

  const { data, isError, isLoading } = getStationById;

  if (isLoading) return <h1>Chargement de la station...</h1>;
  if (isError) return <h1>Erreur de chargement de la station...</h1>;

  return (
    <IonCard>
      <IonCardHeader>Station concernée : {data.name}</IonCardHeader>
      <EquipmentCard station={data} equipment={equipment} page='report' />
      <div className='ion-padding'>
        <p style={{ textDecoration: 'underline' }}>Photo(s) descriptive(s)</p>
        <IonRow>
          {equipment.photos &&
            equipment.photos.length > 0 &&
            equipment.photos.map((photo, i) => (
              <IonCol size='4' key={i}>
                <IonImg src={photo.webviewPath} />
              </IonCol>
            ))}
        </IonRow>
      </div>
      <EquipmentValidationButton equipmentId={equipment.id} />
    </IonCard>
  );
};

export default EquipmentContent;
