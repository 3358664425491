import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonImg,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import ProfileAccount from '../components/profile/account/ProfileAccount';
import ProfileContact from '../components/profile/contact/ProfileContact';
import ProfileStats from '../components/profile/stats/ProfileStats';
import { useAuth } from '../context/AuthContext';
// import './Profile.css';
import useAxiosPrivate from '../api/useAxiosPrivate';
import { axiosPublic } from '../api/axios';

const Profile = () => {
  // ***** Hooks *****
  const [section, setSection] = useState<string>('account');

  const history = useHistory();

  const { currentUser, handleLogout } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  // ***** Fonctions de gestion d'évènements *****

  const logout = async () => {
    console.log('logout');
    history.push({
      pathname: '/login',
      state: {
        from: '/profile',
      },
    });
    handleLogout();
    const logout = await axiosPublic('/auth/logout');
    console.log('logout response : ', logout);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons
            className='ion-padding-start'
            slot='start'
            style={{ width: '80px', paddingBlock: '16px' }}
          >
            <IonRouterLink routerLink='/'>
              <IonImg src='/assets/logo.png' />
            </IonRouterLink>
          </IonButtons>
          <IonTitle slot='end'>Profil</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className='ion-padding'>
        <div className='ion-text-end' style={{ marginRight: '8px' }}>
          <IonButton color='danger' onClick={logout}>
            Déconnexion
          </IonButton>
        </div>
        <IonCard style={{ maxWidth: '800px', marginInline: 'auto' }}>
          <IonCardHeader className='ion-text-center'>
            <IonTitle>Bonjour {currentUser?.name}</IonTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonSegment
              value={section}
              onIonChange={(e) => setSection(e.target.value!)}
            >
              <IonSegmentButton value='account'>
                <IonLabel>Compte</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value='stats'>
                <IonLabel>Stats</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value='contact'>
                <IonLabel>Contact</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            {section === 'account' ? (
              <ProfileAccount />
            ) : section === 'stats' ? (
              <ProfileStats />
            ) : (
              <ProfileContact setSection={setSection} />
            )}
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
