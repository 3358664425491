import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import useAxiosPrivate from '../../../../api/useAxiosPrivate';
import { LineStation } from '../../../../models/interfaces/LineStation';

interface SelectDirectionProps {
  lineId: string;
  onDirectionSelect: (direction: string) => void;
}

const SelectDirection = ({
  lineId,
  onDirectionSelect,
}: SelectDirectionProps) => {
  // ***** Hooks *****

  const axiosPrivate = useAxiosPrivate();

  // ***** Tanstack *****

  const { data } = useQuery({
    queryKey: ['terminus', lineId],
    queryFn: async ({ queryKey }) => {
      const response: AxiosResponse<LineStation[]> = await axiosPrivate.get(
        `/terminus/${queryKey[1]}`
      );
      return response.data;
    },
    onError: (error) => {
      console.log('SelectDirection - Error : ', error);
    },
  });

  return (
    <IonItem>
      <IonLabel position='floating'>Direction du quai</IonLabel>
      <IonSelect
        interface='popover'
        placeholder='Direction'
        onIonChange={(e) => onDirectionSelect(e.target.value)}
      >
        {data &&
          data.map((lineStation) => (
            <IonSelectOption
              key={lineStation.id}
              value={lineStation.station.name}
            >
              {lineStation.station.name}
            </IonSelectOption>
          ))}
      </IonSelect>
    </IonItem>
  );
};

export default SelectDirection;
