import { useIonToast } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Equipment } from '../models/interfaces/Equipment';
import useAxiosPrivate from './useAxiosPrivate';

export const useEquipments = () => {
  // ***** Hooks *****

  const axiosPrivate = useAxiosPrivate();

  const [presentToast] = useIonToast();

  // ***** Tanstack *****

  const queryClient = useQueryClient();

  const getEquipements = useQuery({
    queryKey: ['equipments'],
    queryFn: async () => {
      const response: AxiosResponse<Equipment[]> = await axiosPrivate.get(
        '/equipments'
      );
      return response.data;
    },
  });

  const deleteEquipment = useMutation({
    mutationFn: async (equipmentId: string) => {
      const response: AxiosResponse = await axiosPrivate.delete(
        `/equipments/${equipmentId}`
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['station']);
      presentToast({
        message: `Equipement supprimé`,
        duration: 1000,
        position: 'top',
        color: 'success',
        cssClass: 'ion-text-center',
      });
    },
    onError: (error) => {
      console.log('Delete Equipement : ', error);
      presentToast({
        message: `Une erreur est survenue`,
        duration: 2000,
        position: 'top',
        color: 'danger',
        cssClass: 'ion-text-center',
      });
    },
  });

  return { getEquipements, deleteEquipment };
};
