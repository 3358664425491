import { IonIcon, useIonAlert } from '@ionic/react';
import { eyeOutline } from 'ionicons/icons';
import React from 'react';
import { useUpdateData } from '../../../../api/useUpdateData';
import { Station } from '../../../../models/interfaces/Station';
import { ItemType } from './DataItem';

interface DataItemUpdateProps {
  dataType: string;
  item: ItemType;
  station?: Station;
  setStation?: (stationToUpdate: Station) => void;
}

export interface DataToUpdate {
  dataType: string;
  itemId: string;
  dataToUpdate: {
    name: string;
  };
}

const DataItemUpdate = ({
  dataType,
  item,
  station,
  setStation,
}: DataItemUpdateProps) => {
  // ***** Hooks *****

  const [presentAlert] = useIonAlert();

  const updateData = useUpdateData();

  // ***** Fonction de gestion d'évènement *****

  const handleUpdate = () => {
    // if (dataType === 'user') {
    presentAlert({
      header: `Edition de ${dataType}`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Confirmer',
          role: 'confirm',
          cssClass: 'alert-button-confirm',
          handler: (data) => {
            station && setStation && setStation({ ...station, name: data[0] });
            return updateData.mutate({
              dataType,
              itemId: item.id,
              dataToUpdate: {
                name: data[0],
              },
            });
          },
        },
      ],
      inputs: [
        {
          placeholder: item.name,
        },
      ],
    });
    // }
  };

  return (
    <IonIcon
      icon={eyeOutline}
      color='danger'
      slot='end'
      className='equipment-icon'
      onClick={handleUpdate}
    />
  );
};

export default DataItemUpdate;
