import { Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { flagOutline, homeOutline, personOutline } from 'ionicons/icons';
import Login from './pages/Login';
import Register from './pages/Register';
import Report from './pages/Report';
import Home from './pages/Home';
import RequireAuth from './components/RequireAuth';
import Profile from './pages/Profile';
import { useState } from 'react';
import LandingPage from './pages/LandingPage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import PasswordForgot from './pages/PasswordForgot';
import PasswordReset from './pages/PasswordReset';
import Admin from './pages/Admin';

setupIonicReact();

const App: React.FC = () => {
  const [location, setLocation] = useState<string>('/home');

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path='/'>
            <LandingPage />
          </Route>
          <Route path='/login'>
            <Login setLocation={setLocation} />
          </Route>
          <Route path='/password/recover'>
            <PasswordForgot />
          </Route>
          <Route path='/password/reset'>
            <PasswordReset />
          </Route>
          <Route path='/register'>
            <Register />
          </Route>
          <IonTabs>
            <IonRouterOutlet>
              <RequireAuth allowedRoles={['admin', 'user']}>
                <Route exact path='/home'>
                  <Home setLocation={setLocation} />
                </Route>
              </RequireAuth>
              <Route exact path='/report'>
                <RequireAuth allowedRoles={['admin', 'user']}>
                  <Report setLocation={setLocation} />
                </RequireAuth>
              </Route>
              <Route exact path='/profile'>
                <RequireAuth allowedRoles={['admin', 'user']}>
                  <Profile />
                </RequireAuth>
              </Route>
              <Route exact path='/admin'>
                <RequireAuth allowedRoles={['admin']}>
                  <Admin />
                </RequireAuth>
              </Route>
              {/* 
              <Route exact path='/'>
                <Redirect to='/' />
              </Route> */}
            </IonRouterOutlet>

            {/* <RequireAuth allowedRoles={['admin']}>
          <Route exact path='/tab2'>
            <Tab2 />
          </Route>
        </RequireAuth> */}
            <IonTabBar slot='bottom' style={{ height: '60px' }}>
              <IonTabButton
                tab='home'
                href='/home'
                onClick={() => setLocation('/home')}
              >
                <IonIcon
                  icon={homeOutline}
                  size='large'
                  className={location === '/home' ? 'isActive' : ''}
                />
                <IonLabel className={location === '/home' ? 'isActive' : ''}>
                  Accueil
                </IonLabel>
              </IonTabButton>
              <IonTabButton
                tab='report'
                href='/report'
                onClick={() => setLocation('/report')}
              >
                <IonIcon
                  icon={flagOutline}
                  size='large'
                  className={location === '/report' ? 'isActive' : ''}
                />
                <IonLabel className={location === '/report' ? 'isActive' : ''}>
                  Signaler
                </IonLabel>
              </IonTabButton>
              <IonTabButton
                tab='profile'
                href='/profile'
                onClick={() => setLocation('/profile')}
              >
                <IonIcon
                  icon={personOutline}
                  size='large'
                  className={location === '/profile' ? 'isActive' : ''}
                />
                <IonLabel className={location === '/profile' ? 'isActive' : ''}>
                  Profil
                </IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
