import { IonButton, IonIcon, useIonToast } from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { useAuth } from '../../context/AuthContext';
import { Station } from '../../models/interfaces/Station';
import { User } from '../../models/interfaces/User';
import SearchbarStation from './SearchbarStation';
import './FavoriteStations.css';

interface FavoriteStationsProps {
  // On passe en props l'utilisateur connecté pour le cas où on met à jour ses stations favorites
  user?: User;
  // On fait remonter la liste de stations sélectionnées pour le cas où l'on gère un formulaire entier à envoyer (inscription d'un utilisateur)
  onStationsSelect?: (favStations: Station[]) => void;
}

const FavoriteStations = ({
  user,
  onStationsSelect,
}: FavoriteStationsProps) => {
  // ***** Hooks *****
  const [present] = useIonToast();

  const axiosPrivate = useAxiosPrivate();
  const { setCurrentUser } = useAuth();

  const handleFavStation = async (stationToAdd: Station) => {
    // On vérifie si cette station est déjà présente dans la liste des stations favorites
    const isStationInFavList = user?.stations.some(
      (favStation) => favStation.name === stationToAdd.name
    );

    const newFavStationsList = [...user?.stations!, stationToAdd];

    if (isStationInFavList) {
      present({
        message: 'Cette station est déjà dans vos favoris',
        color: 'danger',
        duration: 2000,
        position: 'top',
        cssClass: 'ion-text-center',
      });
      return;
    }

    // Si currentUser existe, cela signifie qu'on est connecté et qu'on est donc dans la page de profil pour modifier nos stations favorites, sinon on envoie notre liste en props pour le formulaire d'inscription
    if (user) {
      try {
        await axiosPrivate.patch(`/user/${user.email}`, {
          stations: newFavStationsList,
        });
        present({
          message: 'Station ajoutée à vos favoris',
          color: 'success',
          duration: 2000,
          position: 'top',
          cssClass: 'ion-text-center',
        });
        setCurrentUser({ ...user, stations: newFavStationsList });
      } catch (error) {
        console.log('FavoriteStations - update stations - Error : ', error);
      }
    } else {
      onStationsSelect && onStationsSelect(newFavStationsList);
    }
  };

  const removeFavStation = async (stationToRemove: Station) => {
    // On retire la station à supprimer dans la liste des stations favorites
    const updateFavStations = user!.stations.filter(
      (favStation) => favStation.name !== stationToRemove.name
    );

    // Si user existe, cela signifie qu'on est connecté et qu'on est donc dans la page de profil pour modifier nos stations favorites, sinon on envoie notre liste en props pour le formulaire d'inscription
    if (user) {
      try {
        await axiosPrivate.patch(`/user/${user.email}`, {
          stations: updateFavStations,
        });
        present({
          message: 'Station retirée de vos favoris',
          color: 'success',
          duration: 2000,
          position: 'top',
          cssClass: 'ion-text-center',
        });
        setCurrentUser({ ...user, stations: updateFavStations });
      } catch (error) {
        console.log('FavoriteStations - update stations - Error : ', error);
      }
    } else {
      onStationsSelect && onStationsSelect(updateFavStations);
    }
  };

  return (
    <div className='ion-padding-top'>
      <h2 className='profil-title ion-padding'>Stations Favorites</h2>
      <SearchbarStation onStationSelect={handleFavStation} />
      <div className='ion-padding-top'>
        {user?.stations && user?.stations.length > 0 ? (
          user?.stations.map((favStation) => (
            <IonButton
              key={favStation.id}
              color='light'
              className='fav-station-button ion-margin-end'
              onClick={() => removeFavStation(favStation)}
            >
              <span className='fav-station-button-text'>{favStation.name}</span>
              <IonIcon
                className='fav-station-icon'
                icon={trashOutline}
                color='danger'
                slot='end'
              />
            </IonButton>
          ))
        ) : (
          <p>Vous n'avez pas encore de station favorite</p>
        )}
      </div>
    </div>
  );
};

export default FavoriteStations;
