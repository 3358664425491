import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

const LandingPage = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons
            className="ion-padding-start"
            slot="start"
            style={{ width: "80px", paddingBlock: "16px" }}
          >
            <IonRouterLink routerLink="/">
              <IonImg src="/assets/logo.png" />
            </IonRouterLink>
          </IonButtons>
          <IonTitle slot="end">Bienvenue sur Easy Métro !</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div style={{ maxWidth: "800px", marginInline: "auto" }}>
          <div className="ion-text-center ion-padding">
            <IonButton color="danger" routerLink="/register">
              Je rejoins la communauté
            </IonButton>
          </div>
          <div className="ion-text-center ion-padding">
            <IonButton color="danger" routerLink="/login">
              J'ai déjà un compte
            </IonButton>
          </div>
          <IonCard className="ion-padding">
            <p style={{ fontWeight: "bold" }} className="ion-text-center">
              Cette application a été créée par un usager, pour les usagers
            </p>
          </IonCard>
          <IonCard className="ion-padding">
            <p>
              Vous avez des difficultés pour vous déplacer dans le métro
              parisien ?
            </p>
            <p className="ion-padding-top">
              Vous voulez faciliter les déplacements des personnes concernées ?
            </p>
            <p className="ion-padding-top">
              Vous préparez un trajet en métro mais vous ne savez pas si les
              stations par lesquelles vous passez ont des{" "}
              <strong>escalators</strong> ou <strong>ascenseurs</strong> ou
              s'ils fonctionnent ?
            </p>
          </IonCard>
          <div style={{ width: "200px", marginInline: "auto" }}>
            <IonImg src="/assets/station-card.PNG" alt="vignette de station" />
          </div>
          <div className="ion-text-center ion-padding">
            <IonButton color="danger" routerLink="/register">
              Je veux en profiter
            </IonButton>
          </div>
          <IonCard className="ion-padding ion-margin-bottom">
            <p>
              Profitez des fiches détaillées des <strong>équipements</strong>{" "}
              pour trouver leur position dans la station
            </p>
            <p className="ion-padding-top">
              Interagissez avec la communauté sur les fiches
              <strong> incidents</strong>
            </p>
          </IonCard>
          <div style={{ width: "250px", marginInline: "auto" }}>
            <IonImg
              src="/assets/equipment-card.PNG"
              alt="vignette d'équipement"
            />
          </div>
          <IonCard className="ion-padding ion-margin-vertical">
            <p>
              Vous vous retrouvez devant un <strong>équipement en panne</strong>{" "}
              ?
            </p>
            <p className="ion-padding-top">
              <strong>Signaler</strong> le en quelques clics pour aider les
              autres usagers
            </p>
          </IonCard>
          <div style={{ width: "200px", marginInline: "auto" }}>
            <IonImg
              src="/assets/report.PNG"
              alt="formulaire de signalement d'incident"
            />
          </div>
          <div className="ion-text-center ion-padding">
            <IonButton color="danger" routerLink="/register">
              Je veux aider moi aussi
            </IonButton>
          </div>
          <IonCard className="ion-padding ion-margin-vertical">
            <p>Vous ne trouvez pas un équipement sur une fiche station ?</p>
            <p className="ion-padding-top">
              Contactez nous via votre profil pour que nous puissions l'ajouter
            </p>
          </IonCard>
          <div style={{ width: "200px", marginInline: "auto" }}>
            <IonImg
              src="/assets/equipment-submit.PNG"
              alt="formulaire de soumission d'équipement manquant"
            />
          </div>
          <div
            className="ion-text-center ion-padding"
            // style={{ width: '100%', display: 'flex' }}
          >
            <IonButton
              style={{
                whiteSpace: "normal",
                height: "auto",
              }}
              color="danger"
              routerLink="/register"
            >
              <span
                style={{
                  color: "white",
                  lineHeight: "1.5em",
                  paddingBlock: ".7em",
                }}
              >
                Je connais des stations avec équipements et je veux vérifier
                s'ils ont déjà été ajoutés
              </span>
            </IonButton>
          </div>
          <IonCard className="ion-padding ion-margin-vertical">
            <p>
              Nous attirons votre attention sur le fait que cette application
              est uniquement vouée à l'<strong>entraide</strong> entre usagers
              et n'est pas là pour critiquer le service de la RATP et IDF
              Mobilités
            </p>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LandingPage;
