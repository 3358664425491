import { IonCard, IonCardHeader } from '@ionic/react';
import { useAuth } from '../../../context/AuthContext';
import { NewUserType } from '../../../models/interfaces/User';
import FavoriteStations from '../../station/FavoriteStations';
import UserForm from '../../form/UserForm';
import { useUserUpdate } from '../../../api/useUserUpdate';

const ProfileAccount = () => {
  // ***** Hooks *****

  const { currentUser } = useAuth();
  const { updateUser } = useUserUpdate();

  // ***** Fonctions de gestion d'évènements *****

  const handleSubmit = (updatedUser: NewUserType) => {
    updateUser.mutate(updatedUser);
  };

  return (
    <IonCard class='ion-padding'>
      <IonCardHeader>
        <h2 className='profil-title'>Informations personnelles</h2>
      </IonCardHeader>
      <UserForm form='update' onSubmit={handleSubmit} />
      {currentUser && <FavoriteStations user={currentUser} />}
    </IonCard>
  );
};

export default ProfileAccount;
