import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { linePictureSrc } from '../../models/formats/picture';
import { Equipment } from '../../models/interfaces/Equipment';
import { Station } from '../../models/interfaces/Station';
import {
  hasActiveReport,
  sortEquipment,
} from '../../models/verification/station';
import EquipmentCard from '../equipment/EquipmentCard';
import HelpIcon from '../HelpIcon';
import ReportDetails from '../report/ReportDetails';
import './StationModal.css';

export interface ReportIncidentType {
  station: Station;
  equipment: Equipment;
}

interface StationModalProps {
  station: Station;
  onDismiss: (
    data?: string | null | undefined | number | ReportIncidentType,
    role?: string
  ) => void;
}

const StationModal = ({ station, onDismiss }: StationModalProps) => {
  // ***** Hooks *****

  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) {
      onDismiss(null, 'cancel');
    }
  }, [currentUser]);

  return (
    <IonPage id='station-modal'>
      <IonHeader className='station-card-header'>
        <IonToolbar>
          <IonButtons style={{ paddingLeft: '7px' }} slot='start'>
            {station.lines_station
              .map((lineStation) => (
                <IonImg
                  key={lineStation.id}
                  src={linePictureSrc(lineStation.line.picture)}
                  alt={lineStation.line.name}
                  style={{ width: '20px', height: '20px', marginRight: '2px' }}
                />
              ))
              .sort()}
          </IonButtons>
          <IonTitle className='ion-text-center' id='modal-title'>
            {station?.name}
          </IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => onDismiss(null, 'cancel')}>
              Fermer
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <div
          className={
            currentUser?.role === 'admin'
              ? 'modal-content-header'
              : 'ion-text-end'
          }
        >
          {currentUser?.role === 'admin' && (
            <IonButton disabled color='success'>
              Connecté en tant qu'admin
            </IonButton>
          )}
          <HelpIcon cssClass='help-icon' />
        </div>
        {station && station.equipments.length > 0 ? (
          sortEquipment(station.equipments).map((equipment) => (
            <IonCard key={equipment.id}>
              <EquipmentCard
                page='modal'
                station={station}
                equipment={equipment}
              />
              <IonCardContent>
                {/* On affiche le contenu de l'incident seulement s'il y en a un actif */}
                {hasActiveReport(equipment) ? (
                  equipment.reports
                    .filter((report) => report.isOver === false)
                    .map(
                      (activeReport) =>
                        currentUser && (
                          <ReportDetails
                            key={activeReport.id}
                            report={activeReport}
                            user={currentUser}
                          />
                        )
                    )
                ) : (
                  <div className='ion-text-center ion-padding-top'>
                    <IonButton
                      color='warning'
                      onClick={() =>
                        onDismiss({ station, equipment }, 'report')
                      }
                    >
                      Signaler un incident
                    </IonButton>
                  </div>
                )}
              </IonCardContent>
            </IonCard>
          ))
        ) : (
          <p className='ion-padding-top'>Cette station n'a pas d'équipements</p>
        )}
      </IonContent>
    </IonPage>
  );
};

export default StationModal;
