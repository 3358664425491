import {
  IonButtons,
  IonCard,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonImg,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import Dashboard from '../components/admin/dashboard/Dashboard';
import EquipmentSubmissions from '../components/admin/equipment-validation/EquipmentSubmissions';
import Manage from '../components/admin/manage/Manage';
import './Admin.css';

const Admin = () => {
  // ***** Hooks *****

  const [section, setSection] = useState<string>('dashboard');

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons
            className='ion-padding-start'
            slot='start'
            style={{ width: '80px', paddingBlock: '16px' }}
          >
            <IonRouterLink routerLink='/'>
              <IonImg src='/assets/logo.png' />
            </IonRouterLink>
          </IonButtons>
          <IonTitle slot='end'>Admin</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='admin-container ion-padding'>
        <IonCard className='admin-page-card'>
          <IonCardHeader>
            <IonSegment
              value={section}
              onIonChange={(e) => setSection(e.target.value!)}
              className='ion-padding-bottom'
            >
              <IonSegmentButton value='dashboard'>
                <IonLabel>Dashboard</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value='manage'>
                <IonLabel>Manage</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value='equipment-submit'>
                <IonLabel>Equipments</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonCardHeader>
          {section === 'dashboard' ? (
            <Dashboard />
          ) : section === 'manage' ? (
            <Manage />
          ) : (
            <EquipmentSubmissions />
          )}
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Admin;
