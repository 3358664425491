import { IonButton } from '@ionic/react';
import React from 'react';
import { useEquipmentUpdate } from '../../../api/useEquipmentUpdate';

interface EquipmentValidationButtonProps {
  equipmentId: string;
}

const EquipmentValidationButton = ({
  equipmentId,
}: EquipmentValidationButtonProps) => {
  // ***** Hooks *****

  const { validateEquipment } = useEquipmentUpdate();

  return (
    <div className='ion-padding'>
      <IonButton onClick={() => validateEquipment.mutate(equipmentId)}>
        Valider l'équipement
      </IonButton>
    </div>
  );
};

export default EquipmentValidationButton;
