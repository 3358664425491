import { useIonToast } from '@ionic/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { DataToDelete } from '../components/admin/manage/data-list/DataItemDelete';
import useAxiosPrivate from './useAxiosPrivate';

export const useDeleteData = () => {
  const axiosPrivate = useAxiosPrivate();

  const [presentToast] = useIonToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: DataToDelete) => {
      const response: AxiosResponse = await axiosPrivate.delete(
        `/${data.data}/${data.itemId}`
      );
      return response.data;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([variables.data]);
      presentToast({
        message: `Elément supprimé`,
        duration: 2000,
        position: 'top',
        color: 'success',
        cssClass: 'ion-text-center',
      });
    },
    onError: (error: any) => {
      console.log('Delete Data - Error : ', error);
      presentToast({
        message: `Une erreur est survenue`,
        duration: 1000,
        position: 'top',
        color: 'danger',
        cssClass: 'ion-text-center',
      });
    },
  });
};
