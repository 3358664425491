import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { AxiosResponse } from 'axios';
import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { axiosPublic } from '../api/axios';
import { useAuth } from '../context/AuthContext';
import './Login.css';

interface LoginProps {
  setLocation: (location: string) => void;
}

const Login = ({ setLocation }: LoginProps) => {
  const [errorMsg, setErrorMsg] = useState<string>('');

  const emailRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);

  // const axiosPrivate = useAxiosPrivate();

  const [present] = useIonToast();
  const history = useHistory();
  const location = useLocation();

  const { currentUser, setCurrentUser, setToken, checkLogin } = useAuth();
  // Pour éviter d'avoir des tokens trop gros, les stations favorites ne sont pas chargées par défaut dans l'accessToken, on a donc cette route pour récupérer les stations favorites d'un utilisateur
  // const { getStationsOfUser } = useStationsOfUser(currentUser?.id);

  // APPEL API FAIT DANS FAVORITES SECTION EN USE EFFECT

  // const getUserStations = async (userId: string) => {
  //   try {
  //     const response: AxiosResponse<Station[]> = await axiosPrivate.get(
  //       `/stations/user/${userId}`
  //     );
  //     return response.data;
  //   } catch (error: any) {
  //     console.log('Fetch FAv Stations - Error : ', error);
  //     throw new Error(error);
  //   }
  // };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    setErrorMsg('');

    if (emailRef.current && passwordRef.current) {
      const email = emailRef.current.value;
      const password = passwordRef.current.value;

      const login = async () => {
        try {
          const response: AxiosResponse<{ accessToken: string }> =
            await axiosPublic.post('/auth/login', {
              email,
              password,
            });
          const { accessToken } = response.data;
          // localStorage.setItem('accessToken', accessToken);

          if (response.status === 201) {
            present({
              message: 'Connexion réussie. Bon retour parmis nous !',
              color: 'success',
              duration: 2000,
              position: 'top',
              cssClass: 'ion-text-center',
            });
            console.log('connexion ok - location : ', location);
            checkLogin(accessToken);
            setToken(accessToken);
            // on ajoute les stations favorites au currentUser qui serviront à certains endroits de l'appli
            // if (currentUser) {
            //   console.log('login - currentuser ok - fetching stations');
            //   const userStations = await getUserStations(currentUser.id);
            //   setCurrentUser({
            //     ...currentUser,
            //     stations: userStations,
            //   });
            // }
            history.push({
              pathname: '/home',
              state: {
                from: '/login',
              },
            });
            setLocation('/home');
          }
        } catch (error: any) {
          setErrorMsg(error.response.data.message);
          console.log('Login - Error : ', error);
        }
      };
      login();
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons
            className='ion-padding-start'
            slot='start'
            style={{ width: '80px', paddingBlock: '16px' }}
          >
            <IonRouterLink routerLink='/'>
              <IonImg src='/assets/logo.png' />
            </IonRouterLink>
          </IonButtons>
          <IonTitle slot='end'>Connexion</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='form-container ion-padding'>
          {errorMsg && (
            <p className='login-error ion-padding ion-margin-bottom'>
              {errorMsg}
            </p>
          )}
          <form onSubmit={handleSubmit} className='login-form'>
            <IonCard className='login-card'>
              <IonCardContent>
                <IonItem>
                  <IonLabel position='floating'>Entrez votre email</IonLabel>
                  <IonInput
                    placeholder='monsieur.dupont@monmail.com'
                    type='email'
                    ref={emailRef}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position='floating'>
                    Entrez votre mot de passe
                  </IonLabel>
                  <IonInput type='password' ref={passwordRef} />
                  <IonNote slot='helper'>
                    <Link to='/password/recover'>Mot de passe oublié ?</Link>
                  </IonNote>
                </IonItem>
                <IonButton className='ion-margin-top' type='submit'>
                  Valider
                </IonButton>
              </IonCardContent>
            </IonCard>
          </form>
          <p className='ion-margin-top'>
            Pas de compte ? <Link to='/register'>Inscrivez-vous</Link>
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
