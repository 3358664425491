import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonImg,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import useAxiosPrivate from '../api/useAxiosPrivate';
import FavoritesSection from '../components/home/FavoritesSection';
import SearchReportsSection from '../components/home/SearchReportsSection';
import SearchStationSection from '../components/home/SearchStationSection';
import { useAuth } from '../context/AuthContext';
import { Station } from '../models/interfaces/Station';
import './Home.css';

interface HomeProps {
  setLocation: (location: string) => void;
}

const Home = ({ setLocation }: HomeProps) => {
  // ***** Hooks *****
  const [section, setSection] = useState<string>('favorites');

  const axiosPrivate = useAxiosPrivate();
  const { currentUser } = useAuth();

  // const getStations = async () => {
  //   try {
  //     const response: AxiosResponse<Station[]> = await axiosPrivate.get(
  //       '/stations'
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.log('Home - Fetch Station Query - Error : ', error);
  //   }
  // };

  // const stationsQuery = useQuery({
  //   queryKey: ['stations'], // unique id for the query
  //   queryFn: getStations,
  // });

  // if (stationsQuery.isLoading) return <h1>Loading...</h1>;
  // if (stationsQuery.isError) return <h1>Une erreur est survenue</h1>;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons
            className='ion-padding-start'
            slot='start'
            style={{ width: '80px', paddingBlock: '16px' }}
          >
            <IonRouterLink routerLink='/'>
              <IonImg src='/assets/logo.png' />
            </IonRouterLink>
          </IonButtons>
          {currentUser?.role === 'admin' && (
            <IonButton
              routerLink='/admin'
              color='success'
              className='ion-padding-start'
            >
              Admin Page
            </IonButton>
          )}
          <IonTitle slot='end'>Accueil</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonCard className='home-section-card'>
          <IonCardHeader>
            <IonSegment
              value={section}
              onIonChange={(e) => setSection(e.target.value!)}
              className='ion-padding-bottom'
            >
              <IonSegmentButton value='favorites'>
                <IonLabel>Favoris</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value='stations'>
                <IonLabel>Stations</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value='reports'>
                <IonLabel>Incidents</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonCardHeader>
          {section === 'favorites' ? (
            <FavoritesSection setLocation={setLocation} />
          ) : section === 'stations' ? (
            <SearchStationSection setLocation={setLocation} />
          ) : (
            <SearchReportsSection setLocation={setLocation} />
          )}
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Home;
