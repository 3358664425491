import { IonIcon } from '@ionic/react';
import {
  chatbubbleEllipses,
  chatbubbleEllipsesOutline,
  ellipse,
} from 'ionicons/icons';
import { useState } from 'react';
import { useUserCommentReports } from '../../api/useUserCommentReports';
import { useAuth } from '../../context/AuthContext';
import { Report } from '../../models/interfaces/Report';
import { NewUserCommentReport } from '../../models/interfaces/UserCommentReport';
import ReportComment from './ReportComment';
import ReportLike from './ReportLike';
import ReportOver from './ReportOver';

interface ReportReactionsProps {
  report: Report;
}

const ReportReactions = ({ report }: ReportReactionsProps) => {
  // ***** Hooks *****
  const [showComments, setShowComments] = useState<boolean>(false);

  const { currentUser } = useAuth();
  const { createUserComment } = useUserCommentReports();

  // ***** Fonctions de vérifications *****

  const hasComments = (report: Report) => {
    return report.comments.some((userComment) => userComment.comment);
  };

  // ***** Fonctions de gestions d'évènements *****

  const handleLikeReport = (like: NewUserCommentReport) => {
    createUserComment.mutate(like);
  };

  const handleOverReport = (over: NewUserCommentReport) => {
    createUserComment.mutate(over);
  };

  const handleReportComment = (comment: NewUserCommentReport) => {
    createUserComment.mutate(comment);
  };

  return (
    <>
      <div className='equipments-icons'>
        {currentUser && (
          <>
            <ReportLike
              report={report}
              user={currentUser}
              onLikeClick={handleLikeReport}
            />
            <ReportOver
              report={report}
              user={currentUser}
              onOverClick={handleOverReport}
            />
          </>
        )}
        <div className='equipment-icon-block'>
          {hasComments(report) && (
            <IonIcon
              icon={ellipse}
              color='success'
              className='counter active-comments'
            />
          )}
          <IonIcon
            icon={
              !showComments ? chatbubbleEllipsesOutline : chatbubbleEllipses
            }
            size='large'
            color='dark'
            className='equipment-icon'
            onClick={() => setShowComments(!showComments)}
          />
        </div>
      </div>
      {showComments && currentUser && (
        <ReportComment
          report={report}
          user={currentUser}
          onCommentClick={handleReportComment}
        />
      )}
    </>
  );
};

export default ReportReactions;
