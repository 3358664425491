import { IonIcon, useIonAlert } from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import React from 'react';
import { useDeleteData } from '../../../../api/useDeleteData';
import { Station } from '../../../../models/interfaces/Station';
import { ItemType } from './DataItem';

interface DataItemDeleteProps {
  dataType: string;
  item: ItemType;
  station?: Station;
  setStation?: (stationToUpdate?: Station) => void;
}

export interface DataToDelete {
  data: string;
  itemId: string;
}

const DataItemDelete = ({
  dataType,
  item,
  station,
  setStation,
}: DataItemDeleteProps) => {
  // ***** Hooks *****

  const [presentAlert] = useIonAlert();

  const deleteData = useDeleteData();

  // ***** Fonction de gestion d'évènement *****

  const handleDelete = () => {
    presentAlert({
      header: 'Êtes-vous sûr de vouloir supprimer cette donnée ?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Confirmer',
          role: 'confirm',
          cssClass: 'alert-button-confirm',
          handler: () => deleteData.mutate({ data: dataType, itemId: item.id }),
        },
      ],
    });
    station && setStation && setStation(undefined);
  };

  return (
    <IonIcon
      icon={trashOutline}
      color='danger'
      slot='end'
      className='equipment-icon'
      onClick={handleDelete}
      // onClick={() => openModal()}
    />
  );
};

export default DataItemDelete;
