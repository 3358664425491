import { useIonToast } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useAuth } from '../context/AuthContext';
import {
  NewUserCommentReport,
  UserCommentReport,
} from '../models/interfaces/UserCommentReport';
import useAxiosPrivate from './useAxiosPrivate';

export const useUserCommentReports = () => {
  // ***** Hooks *****

  const axiosPrivate = useAxiosPrivate();
  const { currentUser, setCurrentUser } = useAuth();

  const [presentToast] = useIonToast();

  // ***** Tanstack *****

  const queryClient = useQueryClient();

  const getUserCommentReport = useQuery({
    queryKey: ['user-comment-reports'],
    queryFn: async () => {
      const response: AxiosResponse<UserCommentReport[]> =
        await axiosPrivate.get('/user-comment-report');
      return response.data;
    },
  });

  const createUserComment = useMutation({
    mutationFn: async (reaction: NewUserCommentReport) => {
      const response: AxiosResponse<UserCommentReport> =
        await axiosPrivate.post('/user-comment-report', reaction);
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['station']);
      currentUser &&
        setCurrentUser({
          ...currentUser,
          comments: [...currentUser.comments, data],
        });
      presentToast({
        message: `Merci pour votre commentaire !`,
        duration: 1000,
        position: 'top',
        color: 'success',
        cssClass: 'ion-text-center',
      });
    },
    onError: (error) => {
      console.log('Report Reactions - UserComment created - Error', error);
      presentToast({
        message: `Une erreur est survenue`,
        duration: 1000,
        position: 'top',
        color: 'danger',
        cssClass: 'ion-text-center',
      });
    },
  });

  const deleteUserComment = useMutation({
    mutationFn: async (comment: UserCommentReport) => {
      const response: AxiosResponse = await axiosPrivate.delete(
        `/user-comment-report/${comment.id}`
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['station']);
      presentToast({
        message: `Commentaire supprimé`,
        duration: 1000,
        position: 'top',
        color: 'success',
        cssClass: 'ion-text-center',
      });
    },
    onError: (error) => {
      console.log('Delete Comment - Error : ', error);
      presentToast({
        message: `Une erreur est survenue`,
        duration: 2000,
        position: 'top',
        color: 'danger',
        cssClass: 'ion-text-center',
      });
    },
  });

  return { getUserCommentReport, createUserComment, deleteUserComment };
};
