import { useIonToast } from '@ionic/react';
import jwtDecode from 'jwt-decode';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DecodedTokenType } from '../api/useAxiosPrivate';
import { User } from '../models/interfaces/User';

interface UserContextType {
  currentUser: User | null;
  setCurrentUser: (user: User | null) => void;
  token: string;
  setToken: (accessToken: string) => void;
  checkLogin: (accessToken: string) => void;
  authLoading: boolean;
  setAuthLoading: (isLoading: boolean) => void;
  handleLogout: () => void;
}

// On crée le context en le typant grâce à l'interface
const CurrentUserContext = createContext<UserContextType | null>(null);

/**
 * Hook custom pour gérer le cas où le context est undefined
 * Sinon on aura une erreur quand on utilise le context dans d'autres composants
 * Ce custom hook permet aussi avec son nom de mieux voir quel context on utilise dans les composants quand on gère plusieurs context en même temps et aussi de n'avoir qu'un seul import à faire.
 *
 */
export const useAuth = () => {
  let context = useContext(CurrentUserContext);
  if (!context) {
    throw new Error(
      `children must be inside the provider otherwise it won't function correctly`
    );
  }
  return context;
};

interface ProviderProps {
  children: ReactNode;
}

const CurrentUserProvider = ({ children }: ProviderProps) => {
  // State qui récupère le user connecté pour pouvoir le partager dans toute l'appli grâce au context.
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  // State qui va récupérer l'accessToken
  const [token, setToken] = useState<string>('');

  // Par défaut, l'authentification est set à true pour dire qu'elle est en cours. Elle n'est passée à false qu'en fin de checklogin.
  const [authLoading, setAuthLoading] = useState<boolean>(false);

  const [present] = useIonToast();

  // fonction qui récupère l'accesToken dans le local storage, vérifie s'il est expiré ou non et met à jour le currentUser si tout est ok.
  const checkLogin = (accessToken: string) => {
    // tester un boolean à false tant que history est pas chargé
    setAuthLoading(true);
    // const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      const decodedToken: DecodedTokenType = jwtDecode(accessToken);

      const { foundUser, exp } = decodedToken;
      const isTokenExpired = exp - Date.now() / 1000 < 0;
      if (isTokenExpired) {
        console.log(
          'Auth Context - Token expiré, redirection vers page de connexion'
        );
        setCurrentUser(null);
        setToken('');
        // localStorage.removeItem('accessToken');
        // present({
        //   message: 'Veuillez vous reconnecter pour accéder à cette page',
        //   color: 'warning',
        //   duration: 3000,
        //   position: 'top',
        //   cssClass: 'ion-text-center',
        // });
        // history.replace('/login');
        // return (
        //   <Route>
        //     <Redirect to='/login' />;
        //   </Route>
        // );
      } else {
        setCurrentUser(foundUser);
        console.log(
          'Auth Context - Token valide, currentUser mis à jour : ',
          foundUser
        );
      }
      setAuthLoading(false);
    } else {
      console.log('Auth Context - No token');
      setAuthLoading(false);
      setCurrentUser(null);
      // present({
      //   message: 'Veuillez vous connecter pour accéder à cette page',
      //   color: 'warning',
      //   duration: 3000,
      //   position: 'top',
      //   cssClass: 'ion-text-center',
      // });
      // return <Redirect to='/login' />;
      // history.replace('/login');

      // history.push('/login');
    }
  };

  useEffect(() => {
    checkLogin(token);
  }, []);

  const handleLogout = () => {
    setToken('');
    setCurrentUser(null);

    present({
      message: 'Vous avez bien été déconnecté',
      color: 'success',
      duration: 3000,
      position: 'top',
      cssClass: 'ion-text-center',
    });
    console.log('AuthContext - Logout : accessToken removed');
  };

  const stateValues = {
    currentUser,
    setCurrentUser,
    token,
    setToken,
    authLoading,
    setAuthLoading,
    checkLogin,
    handleLogout,
  };
  return (
    // On dit ici que le provider (la fonction qui fournit le context grâce à la prop 'value') enveloppe 'children' qui peut être n'importe quel élément. On va utiliser ce provider dans App.tsx pour envelopper toute l'application.
    <CurrentUserContext.Provider value={stateValues}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;
