import {
  IonCol,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonImg,
  IonRow,
} from '@ionic/react';
import { camera } from 'ionicons/icons';
import React from 'react';
import { usePhotoGallery, UserPhoto } from '../../../../hooks/usePhotoGallery';

interface TakePhotoProps {
  onPhotoClick: (photoList: UserPhoto[]) => void;
}

const TakePhoto = ({ onPhotoClick }: TakePhotoProps) => {
  const { takePhoto, photos, startUploadImage } = usePhotoGallery();

  const handleTakePhoto = async () => {
    const newPhotos = await takePhoto();
    startUploadImage(photos);
    onPhotoClick(newPhotos);
  };

  return (
    <div className='ion-padding'>
      <p>Photo (maximum 3)</p>
      <p className='ion-padding-bottom'>
        Cela permet d'aider nos équipes à visualiser l'emplacement de
        l'équipement
      </p>
      <IonGrid>
        <IonRow>
          {photos.map((photo, i) => (
            <IonCol size='4' key={i}>
              <IonImg src={photo.webviewPath} />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
      <IonFabButton onClick={handleTakePhoto}>
        <IonIcon icon={camera} />
      </IonFabButton>
    </div>
  );
};

export default TakePhoto;
