import React, { useState } from 'react';
import { Station } from '../../../../models/interfaces/Station';
import SearchbarStation from '../../../station/SearchbarStation';
import DataItem from './DataItem';

const StationItem = () => {
  // ***** Hooks *****
  const [station, setStation] = useState<Station>();

  return (
    <>
      <div className='ion-padding'>
        <SearchbarStation
          onStationSelect={(selectedStation: Station) =>
            setStation(selectedStation)
          }
        />
      </div>
      {station && (
        <DataItem dataType='stations' item={station} setStation={setStation} />
      )}
    </>
  );
};

export default StationItem;
