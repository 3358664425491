import { Equipment } from '../interfaces/Equipment';
import { Station } from '../interfaces/Station';

export const linePictureSrc = (linePicture: string): string => {
  return `/assets/${linePicture}`;
};

export const getEquipmentTypePicture = (equipment: Equipment) => {
  if (equipment.type.name === 'escalator montant') {
    return '/assets/escalator_montant.png';
  } else if (equipment.type.name === 'escalator descendant') {
    return '/assets/escalator_descendant.png';
  } else if (equipment.type.name === 'ascenseur') {
    return '/assets/ascenseur.png';
  }
  return '';
};

export const getEquipmentDirectionPicture = (
  equipment: Equipment,
  station: Station
) => {
  const foundLineStation = station.lines_station.filter(
    (lineStation) => lineStation.line.name === equipment.line
  );
  return foundLineStation[0].line;
};
