import { IonCard, IonCardContent, IonCardHeader } from '@ionic/react';
import { useUnverifiedEquipments } from '../../../api/useUnverifiedEquipments';
import EquipmentContent from './EquipmentContent';

const EquipmentSubmissions = () => {
  const { getUnverifiedEquipements } = useUnverifiedEquipments();

  const { data, isLoading, isError } = getUnverifiedEquipements;

  if (isLoading) return <h1>Chargement des équipements...</h1>;
  if (isError) return <h1>Erreur de chargement des équipements...</h1>;

  return (
    <IonCard>
      <IonCardHeader>
        <p className='ion-text-center'>
          Liste des équipements en attente de validation
        </p>
      </IonCardHeader>
      <IonCardContent>
        {data &&
          (data.length > 0 ? (
            data.map((equipment) => (
              <EquipmentContent key={equipment.id} equipment={equipment} />
            ))
          ) : (
            <p className='ion-text-center ion-padding'>
              Aucun équipement n'a été proposé
            </p>
          ))}
      </IonCardContent>
    </IonCard>
  );
};

export default EquipmentSubmissions;
