import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { axiosPublic } from '../api/axios';
import UserForm from '../components/form/UserForm';
import { NewUserType, User } from '../models/interfaces/User';

const Register = () => {
  const [present] = useIonToast();
  const history = useHistory();

  const handleSubmit = (newUser: NewUserType) => {
    const register = async () => {
      try {
        const response: AxiosResponse<User> = await axiosPublic.post(
          '/auth/register',
          newUser
        );

        console.log('Register - NewUser - Response : ', response.data);

        if (response.status === 201) {
          present({
            message:
              'Inscription réussie. Vous pouvez maintenant vous connecter',
            color: 'success',
            duration: 3000,
            position: 'top',
            cssClass: 'ion-text-center',
          });
          history.push('/login');
        }
      } catch (error) {
        console.log('Register - NewUser - Error : ', error);
      }
    };
    register();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons
            className='ion-padding-start'
            slot='start'
            style={{ width: '80px', paddingBlock: '16px' }}
          >
            <IonRouterLink routerLink='/'>
              <IonImg src='/assets/logo.png' />
            </IonRouterLink>
          </IonButtons>
          <IonTitle slot='end'>Inscription</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <div style={{ maxWidth: '800px', marginInline: 'auto' }}>
          <p className='ion-margin-vertical'>
            Déjà inscrit(e) ? <Link to='/login'>Connectez-vous</Link>
          </p>
          <IonCard>
            <IonCardContent>
              <p className='ion-padding-bottom'>
                Plus que quelques étapes pour profiter de l'application !
              </p>
              <p className='ion-padding-bottom'>
                Ces informations nous aident à comprendre qui vous êtes et
                comment vous voyagez pour améliorer constamment nos
                fonctionnalités
              </p>
              <p className='ion-padding-bottom'>
                Elles n'auront <strong>en aucun cas</strong> un usage commercial
              </p>
              <UserForm form='post' onSubmit={handleSubmit} />
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Register;
