import {
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router';
import useAxiosPrivate from '../api/useAxiosPrivate';
import { useAuth } from '../context/AuthContext';
import { NewReportType } from '../models/interfaces/Report';
import ReportForm from '../components/form/ReportForm';
import './Report.css';

interface ReportProps {
  setLocation: (location: string) => void;
}

const Report = ({ setLocation }: ReportProps) => {
  // ***** Hooks *****

  const axiosPrivate = useAxiosPrivate();
  const { currentUser, setCurrentUser } = useAuth();

  const [present] = useIonToast();

  const history = useHistory();

  // ***** Tanstack & Query *****

  const queryClient = useQueryClient();

  const createReportQuery = useMutation({
    mutationFn: async (newReport: NewReportType) => {
      const response: AxiosResponse = await axiosPrivate.post(
        '/reports',
        newReport
      );
      console.log('Report Page - Submit Report - Response : ', response.data);
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['station']);
      currentUser &&
        setCurrentUser({
          ...currentUser,
          reports: [...currentUser.reports, data],
        });
      present({
        message: `Incident ajouté, merci pour la communauté !`,
        duration: 2000,
        position: 'top',
        color: 'success',
        cssClass: 'ion-text-center',
      });
      history.push('/home');
      setLocation('/home');
    },
    onError: (error) => {
      console.log('Report Page - Submit Report - Error : ', error);
      present({
        message: `Une erreur est survneue`,
        duration: 1000,
        position: 'top',
        color: 'danger',
        cssClass: 'ion-text-center',
      });
    },
  });

  // ***** Fonction de gestion d'évènements *****

  const handleNewReport = (newReport: NewReportType) => {
    createReportQuery.mutate(newReport);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons
            className='ion-padding-start'
            slot='start'
            style={{ width: '80px', paddingBlock: '16px' }}
          >
            <IonRouterLink routerLink='/'>
              <IonImg src='/assets/logo.png' />
            </IonRouterLink>
          </IonButtons>
          <IonTitle slot='end'>Incident</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className='ion-padding'>
        {currentUser && (
          <ReportForm user={currentUser} onSubmit={handleNewReport} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Report;
