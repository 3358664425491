import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonItem,
  IonLabel,
} from '@ionic/react';
import React from 'react';
import { useAuth } from '../../../context/AuthContext';

const ProfileStats = () => {
  // ***** Hooks *****
  const { currentUser } = useAuth();

  const likeCount = currentUser?.comments.filter(
    (comment) => comment.isLike
  ).length;

  const reportCount = currentUser?.reports.length;

  return (
    <IonCard>
      <IonCardHeader>
        <p>Retrouvez ici des données sur votre activité sur EasyMétro</p>
      </IonCardHeader>
      <IonCardContent>
        {likeCount && likeCount > 0 ? (
          <IonItem>
            <IonLabel>{`Incidents like : ${likeCount}`}</IonLabel>
          </IonItem>
        ) : (
          <p
            className='ion-padding-vertical ion-text-center'
            style={{ borderBottom: '1px solid grey' }}
          >
            Pas encore de like, pensez à remercier les auteurs des signalements
            quand cela vous aide pour planifier vos trajets
          </p>
        )}
        {reportCount && reportCount > 0 ? (
          <IonItem>
            <IonLabel>{`Incidents signalés : ${reportCount}`}</IonLabel>
          </IonItem>
        ) : (
          <p
            className='ion-padding-vertical ion-text-center'
            style={{ borderBottom: '1px solid grey' }}
          >
            Pas encore de signalement, rendez-vous sur la page "Signaler" en bas
            de votre écran si vous constatez un dysfonctionnement sur un
            équipement en station
          </p>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default ProfileStats;
