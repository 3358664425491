import { IonList } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import useAxiosPrivate from '../../../../api/useAxiosPrivate';
import { EquipmentType } from '../../../../models/interfaces/Equipment_Types';
import { Gender } from '../../../../models/interfaces/Gender';
import { Line } from '../../../../models/interfaces/Line';
import { Location } from '../../../../models/interfaces/Location';
import { Station } from '../../../../models/interfaces/Station';
import { User } from '../../../../models/interfaces/User';
import DataItem from './DataItem';
import { DataTypes } from '../DataSelect';
import StationItem from './StationItem';

export interface DataListProps {
  dataType: DataTypes;
}

type Data<T> = T extends 'user'
  ? User[]
  : T extends 'genders'
  ? Gender[]
  : T extends 'stations'
  ? Station[]
  : T extends 'lines'
  ? Line[]
  : T extends 'equipment-types'
  ? EquipmentType[]
  : T extends 'locations'
  ? Location[]
  : never;

const DataList = <T extends keyof Data<T>>({ dataType }: DataListProps) => {
  // ***** Hooks *****

  const axiosPrivate = useAxiosPrivate();

  // ***** Tanstack *****

  const { data, isLoading, isError } = useQuery<Data<T>>({
    queryKey: [`${dataType}`],
    queryFn: async () => {
      const response: AxiosResponse<Data<T>> = await axiosPrivate.get<Data<T>>(
        `/${dataType}`
      );
      return response.data;
    },
  });

  if (isLoading) return <h1>Chargement...</h1>;
  if (isError) return <h1>Erreur lors du chargement des données</h1>;

  // ***** Fonction de gestion d'évènement *****

  return (
    <>
      {dataType === 'stations' ? (
        <StationItem />
      ) : (
        <IonList>
          {data &&
            data.map((item) => (
              <DataItem key={item.id} dataType={dataType} item={item} />
            ))}
        </IonList>
      )}
    </>
  );
};

export default DataList;

//  if ('name' in item) {
//             return <IonItem key={item.id}>{item.name}</IonItem>;
//           } else {
//             return <IonItem key={item.id}>{item.id}</IonItem>;
//           }
