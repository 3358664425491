import { useIonToast } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Report } from '../models/interfaces/Report';
import useAxiosPrivate from './useAxiosPrivate';

export interface UpdateReportProps {
  reportId: string;
  data: {
    duration?: string;
    description?: string;
  };
}

export const useReports = () => {
  // ***** Hooks *****

  const axiosPrivate = useAxiosPrivate();

  const [presentToast] = useIonToast();

  // ***** Tanstack *****

  const queryClient = useQueryClient();

  const getReports = useQuery({
    queryKey: ['reports'],
    queryFn: async () => {
      const response: AxiosResponse<Report[]> = await axiosPrivate.get(
        '/reports'
      );
      return response.data;
    },
  });

  const editReport = useMutation({
    mutationFn: async (queryData: UpdateReportProps) => {
      const response: AxiosResponse = await axiosPrivate.patch(
        `/reports/${queryData.reportId}`,
        queryData.data
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['station']);
    },
    onError: (error) => {
      console.log('Edit Report - Error : ', error);
      presentToast({
        message: `Une erreur est survenue`,
        duration: 2000,
        position: 'top',
        color: 'danger',
        cssClass: 'ion-text-center',
      });
    },
  });

  const closeReport = useMutation({
    mutationFn: async (reportId: string) => {
      const response: AxiosResponse = await axiosPrivate.patch(
        `/reports/close/${reportId}`
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['station']);
      presentToast({
        message: `Vous avez bien clos l'incident`,
        duration: 1000,
        position: 'top',
        color: 'success',
        cssClass: 'ion-text-center',
      });
    },
    onError: (error) => {
      console.log('Close Report - Error : ', error);
      presentToast({
        message: `Une erreur est survenue`,
        duration: 2000,
        position: 'top',
        color: 'danger',
        cssClass: 'ion-text-center',
      });
    },
  });

  return { getReports, editReport, closeReport };
};
