import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Station } from '../models/interfaces/Station';
import useAxiosPrivate from './useAxiosPrivate';

export const useStations = () => {
  const axiosPrivate = useAxiosPrivate();

  const getStations = useQuery({
    queryKey: ['stations'],
    queryFn: async () => {
      const response: AxiosResponse<Station[]> = await axiosPrivate.get(
        '/stations'
      );
      return response.data;
    },
  });

  return { getStations };
};
