import { IonLoading, useIonToast } from '@ionic/react';
import React, { ReactNode } from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import { useAuth } from '../context/AuthContext';

interface RequireAuthProps {
  // props dans laquelle on spécifie quels rôles seront autorisés à accéder à ces routes
  allowedRoles: string[];
  children: ReactNode;
}

interface LocationType {
  from: string;
}

const RequireAuth = ({ children, allowedRoles, ...rest }: RequireAuthProps) => {
  const { currentUser, authLoading } = useAuth();
  const [present] = useIonToast();
  const location = useLocation<LocationType>();

  if (authLoading) {
    console.log('RequireAuth - context loading');
    return <IonLoading isOpen={authLoading} message='Loading...' />;
  }

  if (!currentUser) {
    console.log('RequireAuth - pas de currentUser', location);

    if (
      location.state &&
      location.state.from !== '/profile' &&
      location.state.from !== '/login'
    ) {
      present({
        message: 'Authentification expirée. Merci de vous reconnecter',
        duration: 3000,
        position: 'top',
        color: 'warning',
        cssClass: 'ion-text-center',
      });
    }
    return (
      <Redirect
        to={{ pathname: '/login', state: { from: location.pathname } }}
      />
    );
  }

  // Si l'authentification est terminée, on vérifie le type de currentUser que l'on a
  if (currentUser && allowedRoles.includes(currentUser.role)) {
    console.log('RequireAuth - role ok : ', currentUser);
    // OUTLET ???
    return <Route {...rest} render={() => children} />;
  } else {
    console.log('RequireAuth - role pas ok : ', currentUser);
    present({
      message: "Vous n'avez pas les autorisations pour accéder à cette page",
      duration: 3000,
      position: 'top',
      color: 'warning',
      cssClass: 'ion-text-center',
    });

    return <Redirect to='/login' />;
  }
};

export default RequireAuth;
