import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { EquipmentType } from '../models/interfaces/Equipment_Types';
import useAxiosPrivate from './useAxiosPrivate';

export const useEquipmentTypes = () => {
  const axiosPrivate = useAxiosPrivate();

  return useQuery({
    queryKey: ['equipments-types'],
    queryFn: async () => {
      const response: AxiosResponse<EquipmentType[]> = await axiosPrivate.get(
        '/equipment-types'
      );
      return response.data;
    },
  });
};
