import { useIonToast } from '@ionic/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { NewEquipmentType } from '../models/interfaces/Equipment';
import useAxiosPrivate from './useAxiosPrivate';

export const useEquipmentAdd = () => {
  // ***** Hooks *****

  const axiosPrivate = useAxiosPrivate();

  const [presentToast] = useIonToast();

  // ***** Tanstack *****

  const createEquipment = useMutation({
    mutationFn: async (newEquipment: NewEquipmentType) => {
      const response: AxiosResponse = await axiosPrivate.post(
        '/equipments',
        newEquipment
      );
      return response.data;
    },
    onSuccess: () => {
      presentToast({
        message: `Nouvel équipement soumis ! Il sera prochainement étudier par nos équipes`,
        duration: 2000,
        position: 'top',
        color: 'success',
        cssClass: 'ion-text-center',
      });
      // setSection('account');
    },
    onError: (error: any) => {
      console.log('Profile Contact - Equipment Submit - Error', error);
      if (error.response.status === 400) {
        presentToast({
          message: error.response.data.message,
          duration: 5000,
          position: 'top',
          color: 'danger',
          cssClass: 'ion-text-center',
        });
      } else {
        presentToast({
          message: `Une erreur est survenue`,
          duration: 1000,
          position: 'top',
          color: 'danger',
          cssClass: 'ion-text-center',
        });
      }
    },
  });

  return { createEquipment };
};
