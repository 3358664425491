import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useEquipmentTypes } from '../../../../api/useEquipmentTypes';
import { EquipmentType } from '../../../../models/interfaces/Equipment_Types';

interface SelectEquipmentTypeProps {
  onEquipmentTypeSelect: (equipmentType: EquipmentType) => void;
}

const SelectEquipmentType = ({
  onEquipmentTypeSelect,
}: SelectEquipmentTypeProps) => {
  // ***** Hooks *****

  const {
    data: equipmentTypes,
    isError: isErrorEquipmentTypes,
    isLoading: isLoadingEquipmentTypes,
  } = useEquipmentTypes();

  // ***** Gestions d'erreur

  if (isLoadingEquipmentTypes)
    return <h1>Chargement des types d'équipement...</h1>;
  if (isErrorEquipmentTypes) return <h1>Une erreur est survenue</h1>;

  return (
    <IonItem>
      <IonLabel position='floating'>Type d'équipement</IonLabel>
      <IonSelect
        interface='popover'
        placeholder="Sélectionner l'équipement"
        onIonChange={(e) => onEquipmentTypeSelect(e.target.value)}
      >
        {equipmentTypes &&
          equipmentTypes.map((equipmentType) => (
            <IonSelectOption key={equipmentType.id} value={equipmentType}>
              {equipmentType.name}
            </IonSelectOption>
          ))}
      </IonSelect>
    </IonItem>
  );
};

export default SelectEquipmentType;
