import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Equipment } from '../models/interfaces/Equipment';
import useAxiosPrivate from './useAxiosPrivate';

export const useUnverifiedEquipments = () => {
  // ***** Hooks *****

  const axiosPrivate = useAxiosPrivate();

  // ***** Tanstack *****

  const getUnverifiedEquipements = useQuery({
    queryKey: ['equipments-unverified'],
    queryFn: async () => {
      const response: AxiosResponse<Equipment[]> = await axiosPrivate.get(
        '/equipments/unverified'
      );
      return response.data;
    },
  });

  return { getUnverifiedEquipements };
};
