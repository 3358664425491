import { IonIcon, IonItem } from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import { useUserCommentReports } from '../../api/useUserCommentReports';
import { useAuth } from '../../context/AuthContext';
import { User } from '../../models/interfaces/User';
import { UserCommentReport } from '../../models/interfaces/UserCommentReport';
import { calculateDaysSinceNow } from '../../models/math/date';

interface ReportCommentItemProps {
  userComment: UserCommentReport;
  user: User;
}

const ReportCommentItem = ({ userComment, user }: ReportCommentItemProps) => {
  // ***** Hooks *****

  const { setCurrentUser } = useAuth();
  const { deleteUserComment } = useUserCommentReports();

  // ***** Fonction de vérification *****

  const isUserAuthorOfComment = (comment: UserCommentReport) => {
    return user.id === comment.userId;
  };

  // ***** Divers *****

  const commentDate = (comment: UserCommentReport) => {
    const dayDiff = calculateDaysSinceNow(comment.created_at);
    const result =
      dayDiff < 1 ? "aujourd'hui" : `il y a ${Math.ceil(dayDiff)} jours`;
    return result;
  };

  // ***** Fonction de gestions d'évènements *****

  const handleDeleteComment = (commentToRemove: UserCommentReport) => {
    if (user) {
      const updatedUserComments = [...user.comments].filter(
        (comment) => comment.id !== commentToRemove.id
      );

      deleteUserComment.mutate(commentToRemove);
      setCurrentUser({ ...user, comments: updatedUserComments });
    }
  };

  return (
    <>
      <IonItem id='report-comment'>
        <p
          className={
            isUserAuthorOfComment(userComment)
              ? 'report-comment-text padding'
              : 'report-comment-text'
          }
        >
          {userComment.comment}
        </p>
        {isUserAuthorOfComment(userComment) && (
          <div className='report-comment-icons'>
            <IonIcon
              icon={trashOutline}
              size='small'
              color='danger'
              className='report-comment-icon'
              onClick={() => handleDeleteComment(userComment)}
            />
          </div>
        )}
      </IonItem>
      <small className='comment-date'>{commentDate(userComment)}</small>
    </>
  );
};

export default ReportCommentItem;
