import { IonCardContent, IonTitle } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { Station } from '../../models/interfaces/Station';
import { calculateDaysSinceNow } from '../../models/math/date';
import EquipmentTypeFilter from '../filters/EquipmentTypeFilter';
import LineFilter from '../filters/LineFilter';
import MaxDaysFilter from '../filters/MaxDaysFilter';
import StationFilter from '../filters/StationFilter';
import StationSmall from '../station/StationSmall';

interface SearchReportsSectionProps {
  setLocation: (location: string) => void;
}

let stationFilter: string = '';
let lineFilter: string = '';
let maxDaysFilter: number = 0;
let equipmentTypeNameFilter: string = '';

const SearchReportsSection = ({ setLocation }: SearchReportsSectionProps) => {
  // ***** Hooks *****
  const [filteredStations, setFilteredStations] = useState<Station[]>([]);
  const axiosPrivate = useAxiosPrivate();

  // ***** Tanstack *****

  const {
    data: stationWithReport,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['stationsReport'],
    queryFn: async () => {
      const response: AxiosResponse<Station[]> = await axiosPrivate.get(
        '/stations/report'
      );
      console.log(
        'SearchReportsSection - Stations With Report - Response : ',
        response.data
      );
      setFilteredStations(response.data);
      return response.data;
    },
  });

  // ***** Fonction de filtre global *****

  const filterStations = () => {
    let updatedFilteredStations = [...stationWithReport!];
    console.log('all filter', updatedFilteredStations);

    if (stationFilter) {
      console.log('all filter - station - stationName : ', stationFilter);
      updatedFilteredStations = updatedFilteredStations.filter(
        ({ name }) => name === stationFilter
      );
      console.log('all filter - station : ', updatedFilteredStations);
    }

    if (lineFilter) {
      updatedFilteredStations = updatedFilteredStations.filter(
        ({ lines_station }) =>
          lines_station.some(({ line }) => line.name === lineFilter)
      );
      console.log('all filter - line : ', updatedFilteredStations);
    }

    if (maxDaysFilter) {
      updatedFilteredStations = updatedFilteredStations.filter(
        ({ equipments }) =>
          equipments.some(({ reports }) =>
            reports.some(
              ({ created_at, isOver }) =>
                !isOver && calculateDaysSinceNow(created_at) < maxDaysFilter
            )
          )
      );
      console.log('all filter - maxDays : ', updatedFilteredStations);
    }

    if (equipmentTypeNameFilter) {
      updatedFilteredStations = updatedFilteredStations.filter(
        ({ equipments }) =>
          equipments.some(
            ({ type, reports }) =>
              type.name === equipmentTypeNameFilter &&
              reports.some(({ isOver }) => !isOver)
          )
      );
      console.log('all filter - equipmentType : ', updatedFilteredStations);
    }

    console.log(
      'all filter - updatedFilteredStations : ',
      updatedFilteredStations
    );

    setFilteredStations(updatedFilteredStations);
  };

  const handleSelectedStation = (stationName: string) => {
    stationFilter = stationName;
    filterStations();
  };

  // ***** Fonctions de gestions d'évènements

  const handleSelectedLine = (lineName: string) => {
    lineFilter = lineName;
    filterStations();
  };

  const handleSelectedMaxDays = (maxDays?: number) => {
    maxDaysFilter = maxDays ?? 0;
    filterStations();
  };

  const handleSelectedEquipementType = (equipmentTypeName: string) => {
    equipmentTypeNameFilter = equipmentTypeName;
    filterStations();
  };

  if (isLoading) return <h2>Loading...</h2>;
  if (isError) return <h2>Erreur du chargement des incidents</h2>;

  return (
    <IonCardContent className='fav-station-container'>
      <IonTitle className='ion-text-center'>Rechercher un incident</IonTitle>
      <p className='ion-text-center ion-padding-vertical'>
        Visualisez en temps réel tous les incidents signalés par la communauté
      </p>
      <p className='ion-text-center ion-padding-vertical'>
        Vous pouvez à tout moment annuler un filtre actif en appuyant sur la
        croix rouge à droite de celui-ci
      </p>
      <h2 className='ion-text-center' style={{ textDecoration: 'underline' }}>
        Filtres
      </h2>
      <StationFilter onStationSelect={handleSelectedStation} />
      <LineFilter onLineSelect={handleSelectedLine} />
      <MaxDaysFilter onMaxDaysSelect={handleSelectedMaxDays} />
      <EquipmentTypeFilter
        onEquipmentTypeSelect={handleSelectedEquipementType}
      />
      {stationWithReport && filteredStations.length > 0 ? (
        filteredStations.map(({ id }) => (
          <StationSmall key={id} id={id} setLocation={setLocation} />
        ))
      ) : (
        <p className='ion-text-center ion-padding-vertical'>
          Aucun résultat correspondant
        </p>
      )}
    </IonCardContent>
  );
};

export default SearchReportsSection;
