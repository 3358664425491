import {
  IonButton,
  IonItem,
  IonLabel,
  IonNote,
  IonTextarea,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { usePhotoGallery, UserPhoto } from '../../../../hooks/usePhotoGallery';
import { errorInfo } from '../../../../models/form-validate/errorMessage';
import { isValid } from '../../../../models/form-validate/validation';
import { NewEquipmentType } from '../../../../models/interfaces/Equipment';
import { EquipmentType } from '../../../../models/interfaces/Equipment_Types';
import { Line } from '../../../../models/interfaces/Line';
import { Location } from '../../../../models/interfaces/Location';
import { Station } from '../../../../models/interfaces/Station';
import SearchbarStation from '../../../station/SearchbarStation';
import SelectDirection from './SelectDirection';
import SelectEquipmentType from './SelectEquipmentType';
import SelectLine from './SelectLine';
import SelectLocation from './SelectLocation';
import TakePhoto from './TakePhoto';

interface EquipmentFormProps {
  onSubmit: (equipmentToSubmit: NewEquipmentType) => void;
}

const EquipmentForm = ({ onSubmit }: EquipmentFormProps) => {
  // ***** Hooks *****
  const [takenPhotos, setTakenPhotos] = useState<UserPhoto[]>([]);

  const [errorMsg, setErrorMsg] = useState<string>('');
  const [station, setStation] = useState<Station>();
  const [equipmentType, setEquipmentType] = useState<EquipmentType>();
  const [startLocation, setStartLocation] = useState<Location>();
  const [endLocation, setEndLocation] = useState<Location>();
  const [line, setLine] = useState<Line>();
  const [direction, setDirection] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const descriptionRef = useRef<HTMLIonTextareaElement>(null);

  const { deletePhotos } = usePhotoGallery();

  useEffect(() => {
    setEquipmentType(undefined);
    setStartLocation(undefined);
    setEndLocation(undefined);
    setLine(undefined);
    setDirection(undefined);
  }, [station]);

  // ***** Fonction d'affichage *****

  const locationDescription = (type: string) => {
    if (type === 'escalator montant') {
      return "Le départ d'un escalator montant correspond à son emplacement/niveau du bas";
    } else if (type === 'escalator descendant') {
      return "Le départ d'un escalator descendant correspond à son emplacement/niveau du haut";
    } else {
      return "Le départ d'un ascenseur correspond à son emplacement/niveau le plus bas et son arrivée le niveau le plus haut";
    }
  };

  // ***** Fonctions de gestion d'évènement *****

  const handleSelectedStation = (stationConcerned: Station) => {
    setIsLoading(true);
    setTimeout(() => {
      setStation(stationConcerned);
      setIsLoading(false);
    }, 100);
  };

  const handleSelectedEquipementType = (equipmentTypeToAdd: EquipmentType) => {
    setEquipmentType(equipmentTypeToAdd);
  };

  const handleSelectEquipmentStart = (location: Location) => {
    setStartLocation(location);
    if (
      endLocation &&
      endLocation.name !== 'quai' &&
      line &&
      location.name !== 'quai'
    ) {
      setLine(undefined);
    }
    if (
      endLocation &&
      endLocation.name !== 'quai' &&
      direction &&
      location.name !== 'quai'
    ) {
      setDirection(undefined);
    }
  };

  const handleSelectEquipmentEnd = (location: Location) => {
    setEndLocation(location);
    if (
      startLocation &&
      startLocation.name !== 'quai' &&
      line &&
      location.name !== 'quai'
    ) {
      setLine(undefined);
    }
    if (
      startLocation &&
      startLocation.name !== 'quai' &&
      direction &&
      location.name !== 'quai'
    ) {
      setDirection(undefined);
    }
  };

  const handleSelectLine = (trackLine: Line) => {
    setLine(trackLine);
  };

  const handleSelectDirection = (trackDirection: string) => {
    setDirection(trackDirection);
  };

  const handlePhotos = (photos: UserPhoto[]) => {
    console.log('Equipment Submit - taken photos : ', photos);
    setTakenPhotos(photos);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let description = descriptionRef.current?.value;

    if (!station) {
      setErrorMsg('La station est obligatoire');
      setTimeout(() => {
        setErrorMsg('');
      }, 2000);
      return;
    }

    if (!equipmentType) {
      setErrorMsg("Le type d'équipement est obligatoire");
      setTimeout(() => {
        setErrorMsg('');
      }, 2000);
      return;
    }

    if (!startLocation || !endLocation) {
      setErrorMsg('Les départs et arrivées sont obligatoires');
      setTimeout(() => {
        setErrorMsg('');
      }, 2000);
      return;
    }

    if (
      (startLocation.name === 'quai' || endLocation.name === 'quai') &&
      (!direction || !line)
    ) {
      setErrorMsg(
        "La direction et la ligne sont obligatoires si l'équipement part d'un quai ou arrive sur un quai"
      );
      setTimeout(() => {
        setErrorMsg('');
      }, 4000);
      return;
    }

    if (startLocation.name === 'rue' && endLocation.name === 'rue') {
      setErrorMsg("Combinaison d'emplacement incorrecte");
      setTimeout(() => {
        setErrorMsg('');
      }, 2000);
      return;
    }

    if (
      description &&
      typeof description === 'string' &&
      !isValid('description', description)
    ) {
      setErrorMsg(errorInfo('description'));
      setTimeout(() => {
        setErrorMsg('');
      }, 3000);
    }

    if (description === '') description = undefined;

    const newEquipment: NewEquipmentType = {
      station,
      type: equipmentType,
      start: startLocation,
      end: endLocation,
      line: line?.name,
      direction,
      description,
      isVerified: false,
      photos: takenPhotos.length > 0 ? takenPhotos : undefined,
    };

    onSubmit(newEquipment);
    deletePhotos();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3 className='ion-padding-start ion-padding-vertical report-subtitle'>
        Station concernée
      </h3>
      <SearchbarStation onStationSelect={handleSelectedStation} />
      {station && !isLoading && (
        <>
          <div className='ion-text-center ion-padding'>
            <IonButton
              disabled
              style={{
                maxWidth: '100%',
              }}
            >
              <span className='fav-station-button-text'>{station.name}</span>
            </IonButton>
          </div>
          <SelectEquipmentType
            onEquipmentTypeSelect={handleSelectedEquipementType}
          />
        </>
      )}
      {equipmentType && (
        <>
          <h3 className='ion-padding-start ion-padding-vertical report-subtitle'>
            Emplacement
          </h3>
          <p className='ion-padding-start' style={{ marginBottom: '1em' }}>
            {locationDescription(equipmentType.name)}
          </p>
          <SelectLocation
            side='start'
            onEquipmentTypeSelect={handleSelectEquipmentStart}
          />
          <SelectLocation
            side='end'
            onEquipmentTypeSelect={handleSelectEquipmentEnd}
          />
        </>
      )}
      {station &&
        (startLocation?.name === 'quai' || endLocation?.name === 'quai') && (
          <>
            <p className='ion-padding-start' style={{ marginTop: '1em' }}>
              Merci d'indiquer la ligne et la direction du quai où se trouve
              l'équipement
            </p>
            <SelectLine
              lineStations={station?.lines_station}
              onLineSelect={handleSelectLine}
            />
          </>
        )}
      {(startLocation?.name === 'quai' || endLocation?.name === 'quai') &&
        line && (
          <SelectDirection
            lineId={line.id}
            onDirectionSelect={handleSelectDirection}
          />
        )}
      {endLocation && (
        <>
          <IonItem>
            <IonLabel position='floating'>Description</IonLabel>
            <IonTextarea
              className='report-textarea'
              placeholder='Description'
              ref={descriptionRef}
              rows={1}
              autoGrow
            />
            <IonNote slot='helper' className='report-note'>
              Pour bien situer l'équipement (quel niveau, quel côté de
              correspondance / sortie...)
            </IonNote>
          </IonItem>
          <TakePhoto onPhotoClick={handlePhotos} />
        </>
      )}
      <IonButton className='ion-margin-top' type='submit'>
        Valider
      </IonButton>

      {errorMsg && (
        <p className='ion-padding register-error' color='danger'>
          {errorMsg}
        </p>
      )}
    </form>
  );
};

export default EquipmentForm;
