import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { AxiosResponse } from 'axios';
import React, { useRef } from 'react';
import { axiosPublic } from '../api/axios';

const PasswordForgot = () => {
  const emailRef = useRef<HTMLIonInputElement>(null);

  const [present] = useIonToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (emailRef.current) {
      const email = emailRef.current.value;

      present({
        message: 'Un mail vous a été envoyé',
        color: 'success',
        duration: 2000,
        position: 'top',
        cssClass: 'ion-text-center',
      });

      try {
        const response: AxiosResponse = await axiosPublic.post(
          'auth/password/forgot',
          { email }
        );
        console.log('Forgot Password - Response : ', response.data);
      } catch (error) {
        console.log('Forgot Password - Error : ', error);
      }
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons
            className='ion-padding-start'
            slot='start'
            style={{ width: '80px', paddingBlock: '16px' }}
          >
            <IonRouterLink routerLink='/'>
              <IonImg src='/assets/logo.png' />
            </IonRouterLink>
          </IonButtons>
          <IonTitle slot='end'>Mot de passe oublié ?</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='form-container ion-padding'>
          <form onSubmit={handleSubmit}>
            <p style={{ marginInline: '10px' }} className='ion-margin-bottom'>
              Entrez votre adresse email ci-dessous et nous vous enverrons un
              lien pour réinitialiser votre mot de passe
            </p>
            <IonCard className='login-card'>
              <IonCardContent>
                <IonItem>
                  <IonLabel position='floating'>Entrez votre email</IonLabel>
                  <IonInput
                    placeholder='monsieur.dupont@monmail.com'
                    type='email'
                    ref={emailRef}
                    required
                  />
                </IonItem>
                <IonButton className='ion-margin-top' type='submit'>
                  Valider
                </IonButton>
              </IonCardContent>
            </IonCard>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PasswordForgot;
