import { IonButton, IonInput, IonItem, IonLabel } from '@ionic/react';
import React, { FormEvent, useRef } from 'react';

export interface UnknwonDataType {
  name: string;
}

interface UnknownDataFormProps {
  onSubmit: (unknwonData: UnknwonDataType) => void;
}

const UnknownDataForm = ({ onSubmit }: UnknownDataFormProps) => {
  // ***** Hooks *****

  const nameRef = useRef<HTMLIonInputElement>(null);

  // ***** Fonction de gestion d'évènement *****

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (nameRef.current) {
      const name = nameRef.current.value;
      console.log(name);
      typeof name === 'string' && onSubmit({ name });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <IonItem>
        <IonLabel position='floating'>Nom</IonLabel>
        <IonInput ref={nameRef} placeholder='Nom' />
      </IonItem>
      <IonButton type='submit' style={{ marginTop: '20px' }}>
        Valider
      </IonButton>
    </form>
  );
};

export default UnknownDataForm;
