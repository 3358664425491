import { IonCardContent, IonTitle } from '@ionic/react';
import { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { useAuth } from '../../context/AuthContext';
import { Station } from '../../models/interfaces/Station';
import StationSmall from '../station/StationSmall';

interface FavoritesSectionProps {
  setLocation: (location: string) => void;
}

const FavoritesSection = ({ setLocation }: FavoritesSectionProps) => {
  // ***** Hooks *****

  const { currentUser, setCurrentUser } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const getUserStations = async (userId: string) => {
      try {
        const response: AxiosResponse<Station[]> = await axiosPrivate.get(
          `/stations/user/${userId}`
        );
        currentUser &&
          setCurrentUser({
            ...currentUser,
            stations: response.data,
          });
        return response.data;
      } catch (error: any) {
        console.log('Fetch FAv Stations - Error : ', error);
        throw new Error(error);
      }
    };
    if (currentUser) {
      console.log('Favorites Section - useEffect - fetch user favorites');
      getUserStations(currentUser.id);
    }
  }, []);

  return (
    <IonCardContent className='fav-station-container'>
      <IonTitle className='ion-text-center'>Stations Favorites</IonTitle>
      {currentUser &&
        (currentUser.stations && currentUser.stations.length > 0 ? (
          currentUser.stations.map(({ id }) => (
            <StationSmall key={id} id={id} setLocation={setLocation} />
          ))
        ) : (
          <>
            <p className='ion-text-center ion-padding-vertical'>
              Vous n'avez pas encore choisi de station favorite
            </p>
            <p className='ion-padding-bottom ion-text-center'>
              Rendez vous dans vos informations personnelles sur votre profil
              pour en ajouter
            </p>
            <p
              className='ion-text-center'
              style={{ textDecoration: 'underline' }}
            >
              Astuce
            </p>
            <p className='ion-text-center'>
              Vous pouvez aussi en ajouter en cliquant sur l'étoile en haut à
              droite de chacune des stations
            </p>
          </>
        ))}
    </IonCardContent>
  );
};

export default FavoritesSection;
