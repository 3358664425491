import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Station } from '../models/interfaces/Station';
import useAxiosPrivate from './useAxiosPrivate';

export const useStationById = (stationId: string) => {
  const axiosPrivate = useAxiosPrivate();

  const getStationById = useQuery({
    queryKey: ['station', stationId],
    queryFn: async () => {
      const response: AxiosResponse<Station> = await axiosPrivate.get(
        `/stations/${stationId}`
      );
      console.log('getstation by id : ', response.data);
      return response.data;
    },
  });

  return { getStationById };
};
