import { IonCardContent, IonCardHeader, IonIcon, IonImg } from '@ionic/react';
import { ellipse } from 'ionicons/icons';
import {
  getEquipmentDirectionPicture,
  getEquipmentTypePicture,
  linePictureSrc,
} from '../../models/formats/picture';
import { Equipment } from '../../models/interfaces/Equipment';
import { Station } from '../../models/interfaces/Station';
import { hasActiveReport } from '../../models/verification/station';
import EquipmentTitle from './EquipmentTitle';

interface EquipmentCardProps {
  page: 'modal' | 'report';
  station: Station;
  equipment: Equipment;
}

const EquipmentCard = ({ station, equipment, page }: EquipmentCardProps) => {
  return (
    <>
      <IonCardHeader className='equipment-header'>
        {equipment.line && (
          <IonImg
            src={getEquipmentTypePicture(equipment)}
            style={{
              width: '30px',
              height: '30px',
              marginRight: '2px',
            }}
          />
        )}
        <EquipmentTitle equipment={equipment} />
        {page === 'modal' && (
          <IonIcon
            icon={ellipse}
            color={hasActiveReport(equipment) ? 'danger' : 'success'}
          />
        )}
      </IonCardHeader>
      <IonCardContent className={page === 'modal' ? 'equipment-content' : ''}>
        <div className='ion-margin-top'>
          <p className='equipment-prop-title'>Sens</p>
          <p>
            {equipment.start.name} vers {equipment.end.name}
          </p>
        </div>
        {/* Si la direction existe, on l'affiche */}
        {equipment.direction && (
          <div className='ion-margin-top'>
            <span className='equipment-prop-title'>Direction</span>
            <span> (quai)</span>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p>{equipment.direction}</p>
              <IonImg
                src={linePictureSrc(
                  getEquipmentDirectionPicture(equipment, station).picture
                )}
                alt={getEquipmentDirectionPicture(equipment, station).name}
                style={{
                  width: '20px',
                  height: '20px',
                  marginLeft: '5px',
                }}
              />
            </div>
          </div>
        )}
        <div className='ion-margin-top'>
          <p className='equipment-prop-title'>Description</p>
          <p>
            {equipment.description ? equipment.description : 'Non communiquée'}
          </p>
        </div>
      </IonCardContent>
    </>
  );
};

export default EquipmentCard;
