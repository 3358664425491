import { useEffect, useState } from 'react';
import { User } from '../models/interfaces/User';
import { axiosPrivate } from './axios';
import { useAuth } from '../context/AuthContext';
import useRefreshToken from './useRefreshToken';

export interface DecodedTokenType {
  foundUser: User;
  exp: number;
  iat: number;
}

const useAxiosPrivate = () => {
  // const history = useHistory();
  // const [present] = useIonToast();
  const { setCurrentUser, token: accessToken, setToken } = useAuth();
  const refreshToken = useRefreshToken();
  // const [interceptedRequests, setInterceptedRequests] = useState<string[]>([]);
  const [isFirstTry, setIsFirstTry] = useState(false);

  // On peut considérer les axios interceptor comme des eventListener. Quand on en déclenche un (dans un useEffect), il faut penser à retirer cet "eventListener" pour ne pas qu'il continue à se déclencher en continu
  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        // S'il n'y a pas déjà un Bearer token dans la propriété authorization des headers de la request, on l'ajoute après l'avoir récupéré dans le localstorage.
        if (config.headers && !config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      // Si la réponse est bonne, on la return simplement
      (response) => response,
      // S'il y a une erreur (pas de token ou token expiré par exemple), on ajoute une logique
      async (error) => {
        // On récupère d'abord l'ancienne request faite
        const prevRequest = error?.config;
        // On vérifie en plus si la propriété sent de l'ancienne requête est false/undefined/null, c'est à dire si c'est la première fois que l'on reçoit cette request, cela permet d'éviter d'avoir une boucle infinie de request et c'est pour ça qu'on la passe à true dès qu'on gère cette erreur.
        if (error?.response?.status === 401 && !isFirstTry) {
          setIsFirstTry(true);

          try {
            const newAccessToken = await refreshToken();
            setToken(newAccessToken);
            console.log('AxiosPrivate - Token Refreshed');
            // refreshing accessToken and add it to the headers
            prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

            return axiosPrivate(prevRequest);
          } catch (error) {
            console.log('AxiosPrivate - Refresh Token expired');
            // present({
            //   message: 'Veuillez vous reconnecter pour accéder à cette page',
            //   color: 'warning',
            //   duration: 3000,
            //   position: 'top',
            //   cssClass: 'ion-text-center',
            // });
            setCurrentUser(null);
            setToken('');
            // history.push('/login');
            // return axiosPrivate(prevRequest);
          }
        }

        // Pour vérifier si le rôle autorise l'action
        if (error?.response?.status === 403 && !isFirstTry) {
          console.log('AxiosPrivate - Response - Erreur 403 : ', error);
          setIsFirstTry(true);
          // present({
          //   message: "Vous n'avez pas l'autorisation pour faire cette action",
          //   color: 'danger',
          //   duration: 3000,
          //   position: 'top',
          //   cssClass: 'ion-text-center',
          // });
          // history.push('/login');
          setCurrentUser(null);
          setToken('');
          // return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [accessToken, refreshToken]);

  return axiosPrivate;
};

export default useAxiosPrivate;
