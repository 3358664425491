import {
  IonSearchbarCustomEvent,
  SearchbarChangeEventDetail,
} from '@ionic/core';
import {
  IonContent,
  IonItem,
  IonList,
  IonPopover,
  IonSearchbar,
} from '@ionic/react';
import React, { useRef, useState } from 'react';
import { useStations } from '../../api/useStations';
import { Station } from '../../models/interfaces/Station';

interface SearchbarStationProps {
  onStationSelect: (station: Station) => void;
}

const SearchbarStation = ({ onStationSelect }: SearchbarStationProps) => {
  // ***** Hooks *****
  const [popoverStations, setPopoverStations] = useState<Station[]>([]);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const popoverRef = useRef<HTMLIonPopoverElement>(null);

  const { getStations } = useStations();

  const { data: stations, isError, isLoading } = getStations;

  if (isLoading) return <h1>Loading...</h1>;
  if (isError)
    return <h1>Erreur de chargement des stations, nouvelle tentative...</h1>;

  const handleSearch = (
    e: IonSearchbarCustomEvent<SearchbarChangeEventDetail>
  ) => {
    // On relie notre popover à notre searchbar pour qu'elle se positionne à côté
    if (popoverRef.current) {
      popoverRef.current.event = e;
    }
    const filteredStations = stations!.filter(
      (station) =>
        e.target.value &&
        station.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setPopoverStations(filteredStations);
    filteredStations.length > 0 && setPopoverOpen(true);
  };
  return (
    <>
      <IonSearchbar
        id='popover-searchBar'
        placeholder='Entrer une station'
        onIonChange={(e) => handleSearch(e)}
        debounce={1000}
      />
      <IonPopover
        isOpen={popoverOpen}
        onDidDismiss={() => setPopoverOpen(false)}
        dismissOnSelect={true}
        ref={popoverRef}
        alignment='center'
      >
        <IonContent>
          <IonList>
            {popoverStations.map(
              (station, i) =>
                i < 5 && (
                  <IonItem
                    key={station.id}
                    button={true}
                    detail={false}
                    onClick={() => onStationSelect(station)}
                  >
                    {station.name}
                  </IonItem>
                )
            )}
          </IonList>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default SearchbarStation;
