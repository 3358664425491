import { Equipment } from '../interfaces/Equipment';
import { Report } from '../interfaces/Report';
import { User } from '../interfaces/User';

export const hasActiveReport = (equipment: Equipment) => {
  return equipment.reports.some((report) => !report.isOver);
};

export const isReportReactedByUser = (
  report: Report,
  type: string,
  user: User
) => {
  return report.comments.some(
    (comment) =>
      comment.userId === user.id &&
      (type === 'isLike' ? comment.isLike : comment.isOver)
  );
};

export const sortEquipment = (equipments: Equipment[]): Equipment[] => {
  return equipments
    .filter((equipment) => equipment.isVerified)
    .sort((equip1, equip2) => {
      if (equip1.type.name < equip2.type.name) {
        return -1;
      }
      if (equip1.type.name > equip2.type.name) {
        return 1;
      }
      return 0;
    });
};
