import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useGenders } from '../api/useGenders';
import { useAuth } from '../context/AuthContext';

interface GenderSelectProps {
  form: 'post' | 'update';
  onGenderSelect: (gender: string) => void;
}

const GenderSelect = ({ form, onGenderSelect }: GenderSelectProps) => {
  console.log('gender component');
  const { currentUser } = useAuth();
  const {
    data: genders,
    isError: isErrorGenders,
    isLoading: isLoadingGenders,
  } = useGenders();

  if (isLoadingGenders) return <h1>Chargement des genres...</h1>;
  if (isErrorGenders) return <h1>Une erreur est survenue</h1>;

  return (
    <IonItem>
      <IonLabel position={form === 'post' ? 'floating' : 'stacked'}>
        Genre {form === 'post' && <span style={{ color: 'red' }}>*</span>}
      </IonLabel>
      <IonSelect
        interface='popover'
        placeholder={
          form === 'post'
            ? 'Sélectionner votre genre'
            : currentUser?.gender.name
        }
        onIonChange={(e) => onGenderSelect(e.target.value)}
      >
        {genders &&
          genders.map((gender) => (
            <IonSelectOption key={gender.id} value={gender.id}>
              {gender.name}
            </IonSelectOption>
          ))}
      </IonSelect>
    </IonItem>
  );
};

export default GenderSelect;
