import { IonItem } from '@ionic/react';
import React from 'react';
import { EquipmentType } from '../../../../models/interfaces/Equipment_Types';
import { Gender } from '../../../../models/interfaces/Gender';
import { Line } from '../../../../models/interfaces/Line';
import { Station } from '../../../../models/interfaces/Station';
import { User } from '../../../../models/interfaces/User';
import { Location } from '../../../../models/interfaces/Location';
import DataItemDelete from './DataItemDelete';
import DataItemUpdate from './DataItemUpdate';

export type ItemType =
  | User
  | Gender
  | Station
  | Line
  | EquipmentType
  | Location;

interface DataItemProps {
  dataType: string;
  item: ItemType;
  setStation?: (stationToUpdate?: Station) => void;
}

const DataItem = ({ dataType, item, setStation }: DataItemProps) => {
  return (
    <IonItem>
      <span>{dataType === 'user' ? (item as User).email : item.name}</span>
      {dataType !== 'user' &&
        (dataType === 'stations' ? (
          <DataItemUpdate
            dataType={dataType}
            item={item}
            station={item as Station}
            setStation={setStation}
          />
        ) : (
          <DataItemUpdate dataType={dataType} item={item} />
        ))}
      {dataType === 'stations' ? (
        <DataItemDelete
          dataType={dataType}
          item={item}
          station={item as Station}
          setStation={setStation}
        />
      ) : (
        <DataItemDelete dataType={dataType} item={item} />
      )}
    </IonItem>
  );
};

export default DataItem;
