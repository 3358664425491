import { useIonToast } from '@ionic/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import useAxiosPrivate from '../../../../api/useAxiosPrivate';
import { NewEquipmentType } from '../../../../models/interfaces/Equipment';
import EquipmentForm from '../../../profile/contact/equipment-submission/EquipmentForm';
import { DataTypes } from '../DataSelect';
import UnknownDataForm, { UnknwonDataType } from './UnknownDataForm';

interface DataFormProps {
  dataType: DataTypes;
}

const DataForm = ({ dataType }: DataFormProps) => {
  // ***** Hooks *****

  const [presentToast] = useIonToast();

  const axiosPrivate = useAxiosPrivate();

  // ***** Tanstack *****

  const createData = useMutation({
    mutationFn: async (data: NewEquipmentType | UnknwonDataType) => {
      const response: AxiosResponse = await axiosPrivate.post(
        `/${dataType}`,
        data
      );
      return response.data;
    },
    onSuccess: () => {
      presentToast({
        message: `Elément ajouté avec succès`,
        duration: 2000,
        position: 'top',
        color: 'success',
        cssClass: 'ion-text-center',
      });
    },
    onError: (error: any) => {
      console.log('Delete Data - Error : ', error);
      presentToast({
        message: `Une erreur est survenue`,
        duration: 1000,
        position: 'top',
        color: 'danger',
        cssClass: 'ion-text-center',
      });
    },
  });

  // ***** Fonction de gestion d'évènement *****

  const handleNewEquipment = (newEquipment: NewEquipmentType) => {
    console.log('admin add - equipment : ', newEquipment);
    const verfiedEquipment = { ...newEquipment, isVerified: true };
    console.log('admin add - equipment modif: ', verfiedEquipment);
    createData.mutate(verfiedEquipment);
  };

  const handleNewData = (newData: UnknwonDataType) => {
    createData.mutate(newData);
  };

  return (
    <>
      <h3
        className='ion-text-center ion-padding'
        style={{ textDecoration: 'underline' }}
      >
        Création d'une donnée de type {dataType}
      </h3>
      {dataType === 'equipments' && (
        <p>
          Pour visualiser le nouvel équipement, rendez-vous sur la fiche de la
          station correspondante
        </p>
      )}
      {dataType === 'equipments' ? (
        <EquipmentForm onSubmit={handleNewEquipment} />
      ) : (
        <UnknownDataForm onSubmit={handleNewData} />
      )}
    </>
  );
};

export default DataForm;
