import { IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';

export type DataTypes =
  | 'user'
  | 'genders'
  | 'stations'
  | 'lines'
  | 'equipments'
  | 'equipment-types'
  | 'locations';

const TABLES = [
  'user',
  'genders',
  'stations',
  'lines',
  'equipments',
  'equipment-types',
  'locations',
] as DataTypes[];

interface DataSelectPRops {
  section: string;
  onSelect: (data: DataTypes) => void;
}

const DataSelect = ({ section, onSelect }: DataSelectPRops) => {
  return (
    <IonSelect
      style={{ maxWidth: '300px', marginInline: 'auto' }}
      interface='popover'
      placeholder='Type de données'
      onIonChange={(e) => onSelect(e.target.value)}
    >
      {section === 'list'
        ? TABLES.map(
            (table, i) =>
              table !== 'equipments' && (
                <IonSelectOption key={`${table}-${i}`} value={table}>
                  {table}
                </IonSelectOption>
              )
          )
        : TABLES.map((table, i) => (
            <IonSelectOption key={`${table}-${i}`} value={table}>
              {table}
            </IonSelectOption>
          ))}
    </IonSelect>
  );
};

export default DataSelect;
