import {
  IonItem,
  IonLabel,
  IonNote,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React from 'react';
import useAxiosPrivate from '../../../../api/useAxiosPrivate';
import { Location } from '../../../../models/interfaces/Location';

interface SelectLocationProps {
  side: 'start' | 'end';
  onEquipmentTypeSelect: (location: Location) => void;
}

const SelectLocation = ({
  side,
  onEquipmentTypeSelect,
}: SelectLocationProps) => {
  // ***** Hooks *****

  const axiosPrivate = useAxiosPrivate();

  // ***** Tanstack *****

  const { data } = useQuery({
    queryKey: ['locations'],
    queryFn: async () => {
      const response: AxiosResponse<Location[]> = await axiosPrivate.get(
        '/locations'
      );
      return response.data;
    },
    onError: (error) => {
      console.log('SelectLocation - Error : ', error);
    },
  });

  return (
    <IonItem>
      <IonLabel position='floating'>
        {side === 'start' ? 'Départ' : 'Arrivée'}
      </IonLabel>
      <IonSelect
        interface='popover'
        placeholder='Localisation'
        onIonChange={(e) => onEquipmentTypeSelect(e.target.value)}
      >
        {data &&
          data.map((location) => (
            <IonSelectOption key={location.id} value={location}>
              {location.name}
            </IonSelectOption>
          ))}
      </IonSelect>
      <IonNote slot='helper' className='report-note'></IonNote>
    </IonItem>
  );
};

export default SelectLocation;
