import { IonIcon, IonLabel, useIonAlert, useIonToast } from '@ionic/react';
import { AxiosResponse } from 'axios';
import { trashOutline } from 'ionicons/icons';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { useAuth } from '../../context/AuthContext';
import { Equipment } from '../../models/interfaces/Equipment';

interface EquipmentTitleProps {
  equipment: Equipment;
}

const EquipmentTitle = ({ equipment }: EquipmentTitleProps) => {
  const { currentUser } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();

  const deleteEquipment = async (equipmentId: string) => {
    try {
      const response: AxiosResponse = await axiosPrivate.delete(
        `/equipments/${equipmentId}`
      );
      presentToast({
        message: `Equipement supprimé`,
        duration: 1000,
        position: 'top',
        color: 'success',
        cssClass: 'ion-text-center',
      });
      return response.data;
    } catch (error: any) {
      console.log('Equipment Delete - Error : ', error);
      presentToast({
        message: `Une erreur est survenue`,
        duration: 2000,
        position: 'top',
        color: 'danger',
        cssClass: 'ion-text-center',
      });
      throw new Error(error);
    }
  };

  const handleEquipmentDelete = (equipmentId: string) => {
    presentAlert({
      header: 'Êtes-vous sûr de vouloir supprimer cet équipement ?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Confirmer',
          role: 'confirm',
          cssClass: 'alert-button-confirm',
          handler: () => deleteEquipment(equipmentId),
        },
      ],
    });
  };

  return (
    <IonLabel
      className={currentUser?.role === 'admin' ? 'equipment-title' : ''}
    >
      <span> {equipment.type.name}</span>
      {currentUser?.role === 'admin' && (
        <IonIcon
          icon={trashOutline}
          color='danger'
          size='large'
          className='equipment-icon'
          onClick={() => handleEquipmentDelete(equipment.id)}
        />
      )}
    </IonLabel>
  );
};

export default EquipmentTitle;
