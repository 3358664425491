import { IonButton, IonInput, IonItem, IonLabel } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { errorInfo } from '../../models/form-validate/errorMessage';
import { isValid } from '../../models/form-validate/validation';
import { NewUserType } from '../../models/interfaces/User';
import GenderSelect from '../GenderSelect';
import HelpIcon from '../HelpIcon';
import './UserForm.css';
interface UserFormProps {
  form: 'post' | 'update';
  onSubmit: (newUser: NewUserType) => void;
}

const UserForm = ({ form, onSubmit }: UserFormProps) => {
  // ***** Hooks *****

  const [errorMsg, setErrorMsg] = useState<string>('');
  const [gender, setGender] = useState<string>('');

  const emailRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);
  const passwordConfirmRef = useRef<HTMLIonInputElement>(null);
  const nameRef = useRef<HTMLIonInputElement>(null);
  const ageRef = useRef<HTMLIonInputElement>(null);
  const cityRef = useRef<HTMLIonInputElement>(null);
  const phoneRef = useRef<HTMLIonInputElement>(null);

  const { currentUser } = useAuth();

  // ***** Fonctions de gestion d'évènements

  const handleGenderSelect = (gender: string) => {
    console.log('UserForm - selected gender : ', gender);
    setGender(gender);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (
      emailRef.current &&
      passwordRef.current &&
      passwordConfirmRef.current &&
      nameRef.current &&
      ageRef.current &&
      cityRef.current &&
      phoneRef.current
    ) {
      let email = emailRef.current.value;
      let password = passwordRef.current.value;
      let passwordConfirm = passwordConfirmRef.current.value;
      let name = nameRef.current.value;
      let age = ageRef.current.value;
      let city = cityRef.current.value;
      let phone = phoneRef.current.value;

      if (form === 'post' && !gender) {
        setErrorMsg('Le genre est obligatoire');
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
        return;
      }

      if (email && typeof email === 'string' && !isValid('email', email)) {
        setErrorMsg(errorInfo('email'));
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
        return;
      }

      if (!email) email = undefined;

      if (
        password &&
        typeof password === 'string' &&
        !isValid('password', password)
      ) {
        setErrorMsg(errorInfo('password'));
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
        return;
      }

      if (password && password !== passwordConfirm) {
        setErrorMsg('Combinaison de mots de passe incorrecte');
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
        return;
      }

      if (!password) password = undefined;

      if (name && typeof name === 'string' && !isValid('name', name)) {
        setErrorMsg(errorInfo('name'));
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
        return;
      }

      if (!name) name = undefined;

      if (age && typeof age === 'string' && !isValid('age', age)) {
        setErrorMsg(errorInfo('age'));
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
        return;
      }

      let numAge: number | undefined = Number(age);

      if ((numAge && numAge < 15) || numAge > 120) {
        setErrorMsg(
          "Il faut avoir au moins 15 ans pour utiliser l'application"
        );
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
        return;
      }

      if (!numAge) numAge = undefined;

      if (
        city &&
        typeof city === 'string' &&
        city.trim().length &&
        !isValid('city', city)
      ) {
        setErrorMsg(errorInfo('city'));
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
        return;
      }

      if (city === '') city = undefined;

      if (
        phone &&
        typeof phone === 'string' &&
        phone.trim().length &&
        !isValid('phone', phone)
      ) {
        setErrorMsg(errorInfo('phone'));
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
        return;
      }

      if (phone === '') phone = undefined;

      const newUser = {
        // ESSAYER if (email) email...
        email,
        password,
        name,
        age: numAge,
        gender: gender ? { id: gender } : undefined,
        city,
        phone,
      };

      if (email || password || name || age || gender || city || phone) {
        onSubmit(newUser);
      } else {
        setErrorMsg(
          'Il faut au moins changer une information pour enregistrer'
        );
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <IonItem>
        <IonLabel position={form === 'post' ? 'floating' : 'stacked'}>
          Email {form === 'post' && <span style={{ color: 'red' }}>*</span>}
        </IonLabel>
        <IonInput
          placeholder={
            form === 'post' ? 'monmail@mondomaine.com' : currentUser?.email
          }
          type='email'
          ref={emailRef}
          required={form === 'post' ? true : false}
        />
      </IonItem>
      <IonItem>
        <IonLabel position={form === 'post' ? 'floating' : 'stacked'}>
          Mot de passe{' '}
          {form === 'post' && <span style={{ color: 'red' }}>*</span>}
        </IonLabel>
        <IonInput
          placeholder={
            form === 'post' ? 'Entrez votre mot de passe' : '********'
          }
          type='password'
          ref={passwordRef}
          required={form === 'post' ? true : false}
        />
      </IonItem>
      <IonItem>
        <IonLabel position={form === 'post' ? 'floating' : 'stacked'}>
          Confirmation mot de passe{' '}
          {form === 'post' && <span style={{ color: 'red' }}>*</span>}
        </IonLabel>
        <IonInput
          placeholder={
            form === 'post' ? 'Confirmer votre mot de passe' : '********'
          }
          type='password'
          ref={passwordConfirmRef}
          required={form === 'post' ? true : false}
        />
      </IonItem>
      <IonItem>
        <IonLabel position={form === 'post' ? 'floating' : 'stacked'}>
          Pseudo {form === 'post' && <span style={{ color: 'red' }}>*</span>}
        </IonLabel>
        <IonInput
          placeholder={
            form === 'post' ? 'Entrez votre pseudo' : currentUser?.name
          }
          ref={nameRef}
          required={form === 'post' ? true : false}
        />
      </IonItem>
      <IonItem>
        <IonLabel position={form === 'post' ? 'floating' : 'stacked'}>
          Age {form === 'post' && <span style={{ color: 'red' }}>*</span>}
        </IonLabel>
        <IonInput
          placeholder={
            form === 'post' ? 'Entrez votre âge' : currentUser?.age.toString()
          }
          ref={ageRef}
          required={form === 'post' ? true : false}
        />
      </IonItem>
      <GenderSelect form={form} onGenderSelect={handleGenderSelect} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IonItem style={{ flexGrow: 1 }}>
          <IonLabel position={form === 'post' ? 'floating' : 'stacked'}>
            Ville
          </IonLabel>
          <IonInput
            placeholder={
              form === 'post'
                ? 'Entrez votre ville'
                : currentUser?.city ?? 'non précisé'
            }
            ref={cityRef}
          />
        </IonItem>
        <HelpIcon
          message="Elle nous permet de mieux comprendre qui utilise l'application pour
            vous fournir de nouvelles fonctionnalités"
          cssClass='help-icon ion-text-end'
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IonItem style={{ flexGrow: 1 }}>
          <IonLabel position={form === 'post' ? 'floating' : 'stacked'}>
            Téléphone
          </IonLabel>
          <IonInput
            placeholder={
              form === 'post'
                ? 'Entrez votre téléphone'
                : currentUser?.phone ?? 'non précisé'
            }
            ref={phoneRef}
          />
        </IonItem>
        <HelpIcon
          message="Il nous permettra de vous prévenir lorsqu'une de vos stations
          favorites subie un incident"
          cssClass='help-icon ion-text-end'
        />
      </div>
      <IonButton className='ion-margin-top' type='submit'>
        {form === 'post' ? 'Valider' : 'Enregistrer'}
      </IonButton>
      {errorMsg && (
        <p className='ion-padding register-error' color='danger'>
          {errorMsg}
        </p>
      )}
    </form>
  );
};

export default UserForm;
