import { IonIcon } from '@ionic/react';
import { thumbsUp, thumbsUpOutline } from 'ionicons/icons';
import { Report } from '../../models/interfaces/Report';
import { User } from '../../models/interfaces/User';
import { NewUserCommentReport } from '../../models/interfaces/UserCommentReport';
import { isReportReactedByUser } from '../../models/verification/station';

interface ReportLikeProps {
  report: Report;
  user: User;
  onLikeClick: (like: NewUserCommentReport) => void;
}

const ReportLike = ({ report, user, onLikeClick }: ReportLikeProps) => {
  const handleLikeReport = async (activeReport: Report) => {
    console.log(
      'StationModal - LikeReport - isReportLiked by currentUser : ',
      isReportReactedByUser(activeReport, 'isLike', user)
    );
    console.log(
      'StationModal - LikeReport - currentUser est le créateur de cet incident : ',
      user.id === activeReport.authorId
    );

    if (
      user &&
      !isReportReactedByUser(activeReport, 'isLike', user) &&
      activeReport.authorId !== user.id
    ) {
      const newReaction: NewUserCommentReport = {
        isLike: true,
        user,
        report: activeReport,
      };

      onLikeClick(newReaction);
    }
  };

  return (
    <div className='equipment-icon-block'>
      {report.likeCounter > 0 && (
        <div className='counter'>{report.likeCounter}</div>
      )}
      {/* L'icône et animation changent selon si l'utilisateur connecté est le créateur de l'incident ou s'il a déjà commenté l'incident */}
      <IonIcon
        icon={
          isReportReactedByUser(report, 'isLike', user)
            ? thumbsUp
            : thumbsUpOutline
        }
        size='large'
        color='tertiary'
        className={
          !isReportReactedByUser(report, 'isLike', user) &&
          report.authorId !== user.id
            ? 'equipment-icon'
            : ''
        }
        onClick={() => handleLikeReport(report)}
      />
    </div>
  );
};

export default ReportLike;
