import { IonCard, IonCardContent, IonItem } from '@ionic/react';
import React from 'react';
import { useEquipments } from '../../../api/useEquipments';
import { useStations } from '../../../api/useStations';
import { useUserCommentReports } from '../../../api/useUserCommentReports';
import { useUsers } from '../../../api/useUsers';

const Dashboard = () => {
  // ***** Hooks *****

  const { getUsers } = useUsers();

  const {
    data: users,
    isError: isErrorUsers,
    isLoading: isLoadingUsers,
  } = getUsers;

  const { getStations } = useStations();

  const {
    data: stations,
    isError: isErrorStations,
    isLoading: isLoadingStations,
  } = getStations;

  const { getEquipements } = useEquipments();

  const {
    data: equipments,
    isError: isErrorEquipments,
    isLoading: isLoadingEquipments,
  } = getEquipements;

  const { getUserCommentReport } = useUserCommentReports();

  const {
    data: userCommentReports,
    isError: isErrorUserCommentReports,
    isLoading: isLoadingUserCommentReports,
  } = getUserCommentReport;

  // ***** Gestion d'erreurs *****

  if (isLoadingUsers) return <h1>Chargement des utilisateurs...</h1>;
  if (isErrorUsers) return <h1>Une erreur est survenue</h1>;
  if (isLoadingStations) return <h1>Chargement des stations...</h1>;
  if (isErrorStations) return <h1>Une erreur est survenue</h1>;
  if (isLoadingEquipments) return <h1>Chargement des stations...</h1>;
  if (isErrorEquipments) return <h1>Une erreur est survenue</h1>;
  if (isLoadingUserCommentReports) return <h1>Chargement des réactions...</h1>;
  if (isErrorUserCommentReports) return <h1>Une erreur est survenue</h1>;

  // ***** Filtres *****

  const maxCommentUser = () => {
    let moreActiveUser = 0;
    users.forEach(({ comments }) => {
      if (comments.length > moreActiveUser) {
        moreActiveUser = comments.length;
      }
    });
    return moreActiveUser;
  };

  const stationsWithAtLeastOneEquipment = [...stations].filter(
    ({ equipments }) => equipments.length > 0
  );

  const equipmentsWithActiveReport = [...equipments].filter(
    ({ reports }) => reports.length > 0
  );

  const userCommentReportLikes = [...userCommentReports].filter(
    ({ isLike }) => isLike
  );
  const userCommentReportOver = [...userCommentReports].filter(
    ({ isOver }) => isOver
  );
  const userCommentReportComments = [...userCommentReports].filter(
    ({ comment }) => comment
  );

  return (
    <IonCard>
      <IonCardContent>
        <h3
          style={{ textDecoration: 'underline' }}
          className='ion-padding-vertical ion-text-center'
        >
          Utilisateurs
        </h3>
        {users && users.length > 0 && (
          <IonItem>
            <p>Nombre d'utilisateurs inscrits : {users.length}</p>
          </IonItem>
        )}
        {users && users.length > 0 && (
          <IonItem>
            <p className='ion-padding-vertical'>
              Nombre de réactions de l'utilisateur le plus actif :{' '}
              {maxCommentUser()}
            </p>
          </IonItem>
        )}
        <h3
          style={{ textDecoration: 'underline' }}
          className='ion-padding-vertical ion-text-center'
        >
          Stations
        </h3>

        {stations && stations.length > 0 && (
          <IonItem>
            <p>Nombre de stations : {stations.length}</p>
          </IonItem>
        )}
        {stations && stations.length > 0 && (
          <IonItem>
            <p className='ion-padding-vertical'>
              Stations avec au moins un équipement :{' '}
              {stationsWithAtLeastOneEquipment.length}
            </p>
          </IonItem>
        )}
        <h3
          style={{ textDecoration: 'underline' }}
          className='ion-padding-vertical ion-text-center'
        >
          Equipements
        </h3>
        {equipments && equipments.length > 0 && (
          <IonItem>
            <p>Nombre d'équipments : {equipments.length}</p>
          </IonItem>
        )}
        {equipments && equipments.length > 0 && (
          <IonItem>
            <p>
              Equipments avec incident actif :{' '}
              {equipmentsWithActiveReport.length}
            </p>
          </IonItem>
        )}
        <h3
          style={{ textDecoration: 'underline' }}
          className='ion-padding-vertical ion-text-center'
        >
          Réactions
        </h3>
        {userCommentReports && userCommentReports.length > 0 && (
          <IonItem>
            <p>Total de réactions : {userCommentReports.length}</p>
          </IonItem>
        )}
        {userCommentReports && userCommentReports.length > 0 && (
          <IonItem>
            <p>Total de likes : {userCommentReportLikes.length}</p>
          </IonItem>
        )}
        {userCommentReports && userCommentReports.length > 0 && (
          <IonItem>
            <p>Total de 'Incident terminé' : {userCommentReportOver.length}</p>
          </IonItem>
        )}
        {userCommentReports && userCommentReports.length > 0 && (
          <IonItem>
            <p>Total de commentaires : {userCommentReportComments.length}</p>
          </IonItem>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default Dashboard;
