import { AxiosResponse } from 'axios';
import { useAuth } from '../context/AuthContext';
import { axiosPrivate } from './axios';

const useRefreshToken = () => {
  const { setToken } = useAuth();

  const refresh = async () => {
    console.log('entering useRefresh');
    const response: AxiosResponse<{ accessToken: string }> =
      await axiosPrivate.get('/auth/refresh');
    setToken(response.data.accessToken);
    return response.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
