import { IonIcon, useIonAlert, useIonToast } from '@ionic/react';
import { alertCircle, alertCircleOutline } from 'ionicons/icons';
import { Report } from '../../models/interfaces/Report';
import { User } from '../../models/interfaces/User';
import { NewUserCommentReport } from '../../models/interfaces/UserCommentReport';
import { isReportReactedByUser } from '../../models/verification/station';

interface ReportOverProps {
  report: Report;
  user: User;
  onOverClick: (over: NewUserCommentReport) => void;
}

const ReportOver = ({ report, user, onOverClick }: ReportOverProps) => {
  // ***** Hooks *****

  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();

  // ***** Fonction de gestion d'évènement

  const handleOverReport = async (activeReport: Report) => {
    console.log(
      'StationModal - OverReport - isReportOvered by currentUser : ',
      isReportReactedByUser(activeReport, 'isOver', user)
    );
    console.log(
      'StationModal - OverReport - currentUser est le créateur de cet incident : ',
      user.id === activeReport.authorId
    );

    if (
      user &&
      !isReportReactedByUser(activeReport, 'isOver', user) &&
      activeReport.authorId !== user.id
    ) {
      const newReaction: NewUserCommentReport = {
        isOver: true,
        user,
        report: activeReport,
      };

      try {
        if (activeReport.overCounter === 2) {
          presentAlert({
            header: `Ce vote va fermer l'incident.`,
            cssClass: 'custom-alert',
            buttons: [
              {
                text: 'Annuler',
                role: 'cancel',
                cssClass: 'alert-button-cancel',
              },
              {
                text: 'Confirmer',
                role: 'confirm',
                cssClass: 'alert-button-confirm',
                handler: () => {
                  onOverClick(newReaction);
                  present({
                    message: `Merci ${user.name}, l'incident est maintenant considéré comme terminé`,
                    duration: 3000,
                    position: 'top',
                    color: 'success',
                    cssClass: 'ion-text-center',
                  });
                },
              },
            ],
          });
        } else {
          onOverClick(newReaction);
        }
      } catch (error) {
        console.log('StationModal - OverReport - AxiosError : ', error);
      }
    }
  };

  return (
    <div className='equipment-icon-block'>
      {report.overCounter > 0 && (
        <div className='counter'>{report.overCounter}</div>
      )}
      {/* L'icône et animation changent selon si l'utilisateur connecté est le créateur de l'incident ou s'il a déjà commenté l'incident */}
      <IonIcon
        icon={
          isReportReactedByUser(report, 'isOver', user)
            ? alertCircle
            : alertCircleOutline
        }
        size='large'
        color='success'
        className={
          !isReportReactedByUser(report, 'isOver', user) &&
          report.authorId !== user.id
            ? 'equipment-icon'
            : ''
        }
        onClick={() => handleOverReport(report)}
      />
    </div>
  );
};

export default ReportOver;
