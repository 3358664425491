import { useIonToast } from '@ionic/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import useAxiosPrivate from './useAxiosPrivate';

export const useEquipmentUpdate = () => {
  // ***** Hooks *****

  const axiosPrivate = useAxiosPrivate();

  const [presentToast] = useIonToast();

  // ***** Tanstack *****

  const queryClient = useQueryClient();

  const validateEquipment = useMutation({
    mutationFn: async (equipmentId: string) => {
      const response: AxiosResponse = await axiosPrivate.patch(
        `/equipments/${equipmentId}`,
        { isVerified: true }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['equipments-unverified']);
      presentToast({
        message: `Equipement validé`,
        duration: 1000,
        position: 'top',
        color: 'success',
        cssClass: 'ion-text-center',
      });
    },
    onError: (error) => {
      console.log('Equipment Validation - Error : ', error);
      presentToast({
        message: `Une erreur est survenue`,
        duration: 2000,
        position: 'top',
        color: 'danger',
        cssClass: 'ion-text-center',
      });
    },
  });

  return { validateEquipment };
};
