import { IonIcon, IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { closeOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { EquipmentType } from '../../models/interfaces/Equipment_Types';

interface EquipmentTypeFilterProps {
  onEquipmentTypeSelect: (equipmentTypeName: string) => void;
}

const EquipmentTypeFilter = ({
  onEquipmentTypeSelect,
}: EquipmentTypeFilterProps) => {
  // ***** Hooks *****

  const [equipmentTypeName, setEquipmentTypeName] = useState<string>('');

  const axiosPrivate = useAxiosPrivate();

  // ***** Tanstack *****

  const {
    data: equipmentTypes,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['equipment-types'],
    queryFn: async () => {
      const response: AxiosResponse<EquipmentType[]> = await axiosPrivate.get(
        '/equipment-types'
      );
      return response.data;
    },
  });

  if (isLoading) return <h2>Loading...</h2>;
  if (isError) return <h2>Erreur du chargement des types d'équipements</h2>;

  // ***** Fonctions gestion d'évènement *****

  const handleEquipmentType = (equipmentTypeName: string) => {
    setEquipmentTypeName(equipmentTypeName);
    onEquipmentTypeSelect(equipmentTypeName);
  };

  const handleCancelEquipmentType = () => {
    setEquipmentTypeName('');
  };

  return (
    <div className='ion-padding'>
      <h3>Par type d'équipement</h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <IonItem style={{ width: '100%' }}>
          <IonSelect
            style={{ width: '100%' }}
            interface='popover'
            value={equipmentTypeName}
            onIonChange={(e) => handleEquipmentType(e.target.value)}
          >
            <IonSelectOption value=''>Sélectionner un type</IonSelectOption>
            {equipmentTypes &&
              equipmentTypes.map(({ id, name }) => (
                <IonSelectOption key={id} value={name}>
                  {name}
                </IonSelectOption>
              ))}
          </IonSelect>
        </IonItem>
        {equipmentTypeName && (
          <IonIcon
            icon={closeOutline}
            size='large'
            color='danger'
            class='equipment-icon'
            onClick={handleCancelEquipmentType}
          />
        )}
      </div>
    </div>
  );
};

export default EquipmentTypeFilter;
