import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import React, { useState } from 'react';
import AddSection from './data-add/AddSection';
import ListSection from './data-list/ListSection';

const Manage = () => {
  const [section, setSection] = useState<string>('list');

  return (
    <IonCard>
      <IonCardHeader>
        <IonSegment
          value={section}
          onIonChange={(e) => setSection(e.target.value!)}
          className='ion-padding-bottom'
        >
          <IonSegmentButton value='list'>
            <IonLabel>Liste</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value='add'>
            <IonLabel>Ajout</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonCardHeader>
      <IonCardContent>
        {section === 'list' ? (
          <ListSection section={section} />
        ) : (
          <AddSection section={section} />
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default Manage;
