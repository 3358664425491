import { useIonToast } from '@ionic/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useAuth } from '../context/AuthContext';
import { NewUserType, User } from '../models/interfaces/User';
import useAxiosPrivate from './useAxiosPrivate';

export const useUsers = () => {
  const [presentToast] = useIonToast();

  const axiosPrivate = useAxiosPrivate();
  const { currentUser, setCurrentUser } = useAuth();

  const getUsers = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const response: AxiosResponse<User[]> = await axiosPrivate.get('/user');
      return response.data;
    },
  });

  const updateUser = useMutation({
    mutationFn: async (updatedUser: NewUserType) => {
      const response: AxiosResponse<User> = await axiosPrivate.patch(
        `/user/${currentUser?.email}`,
        updatedUser
      );
      console.log('Register - NewUser - Response : ', response.data);
      return response.data;
    },
    onSuccess: (data) => {
      presentToast({
        message: 'Profil mis à jour',
        color: 'success',
        duration: 1000,
        position: 'top',
        cssClass: 'ion-text-center',
      });
      setCurrentUser(data);
    },
    onError: (error) => {
      console.log('Register - NewUser - Error : ', error);
      presentToast({
        message: 'Une erreur est survenue',
        color: 'danger',
        duration: 1000,
        position: 'top',
        cssClass: 'ion-text-center',
      });
    },
  });

  return { getUsers, updateUser };
};
