import { IonIcon, IonItem, IonRange, RangeCustomEvent } from '@ionic/react';
import { RangeValue } from '@ionic/core';
import { closeOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import HelpIcon from '../HelpIcon';

interface MaxDaysFilterProps {
  onMaxDaysSelect: (maxDays?: number) => void;
}

const MaxDaysFilter = ({ onMaxDaysSelect }: MaxDaysFilterProps) => {
  // ***** Hooks *****

  const [maxDays, setMaxDays] = useState<RangeValue>();

  // ***** Fonctions gestion d'évènement *****

  const handleMaxDays = (e: RangeCustomEvent) => {
    const days = Number(e.detail.value);
    if (days) {
      setMaxDays(days);
      onMaxDaysSelect(days);
    }
  };

  const handleCancelMaxDays = () => {
    setMaxDays(undefined);
    onMaxDaysSelect(undefined);
  };

  return (
    <div className='ion-padding'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3>Par date</h3>
        <HelpIcon
          message='Affiche les stations dont au moins un des équipements a un incident signalé dans les X derniers jours'
          cssClass='help-icon ion-text-end'
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <IonItem style={{ width: '100%' }}>
          <IonRange
            value={maxDays}
            min={1}
            max={15}
            pin={true}
            onIonKnobMoveEnd={handleMaxDays}
          />
        </IonItem>
        {maxDays && (
          <IonIcon
            icon={closeOutline}
            size='large'
            color='danger'
            class='equipment-icon'
            onClick={handleCancelMaxDays}
          />
        )}
      </div>
    </div>
  );
};

export default MaxDaysFilter;
