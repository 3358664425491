import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Gender } from '../models/interfaces/Gender';
import useAxiosPrivate from './useAxiosPrivate';

export const useGenders = () => {
  const axiosPrivate = useAxiosPrivate();

  return useQuery({
    queryKey: ['genders'],
    queryFn: async () => {
      const response: AxiosResponse<Gender[]> = await axiosPrivate.get(
        '/genders'
      );
      return response.data;
    },
    staleTime: Infinity,
  });
};
